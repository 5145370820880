import { Button, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthManagement from '@layout/AuthManagement'
import useStyles from './emailconfirm-jss'

const EmailConfirmation = () => {
  const classes = useStyles()
  const { status } = useParams()

  return (
    <AuthManagement pageTitle="Squids | Forgot Password Success">
      <Typography variant="h6" className={classes.txForgotPassword}>
        { status === 'success' && 'Email Terkonfirmasi !'}
        { status === 'confirmed' && 'Sudah Terkonfirmasi'}
        { status === 'failed' && 'Token tidak valid!'}
      </Typography>
      <Typography variant="body2" className={clsx(classes.txDescription)} style={{ marginBottom: 8 }}>
        { status === 'success' && 'Email anda telah berhasil dikonfirmasi, silahkan login.'}
        { status === 'confirmed' && 'Sepertinya email anda sudah dikonfirmasi. Silahkan login'}
        { status === 'failed' && ''}
      </Typography>

      <Button
        color="primary"
        component={Link}
        to="/"
      >
        Kembali Ke Halaman Utama
      </Button>
    </AuthManagement>
  )
}

export default EmailConfirmation
