const env = {}

env.saasApi = process.env.REACT_APP_SAAS_API
env.apiPrefix = process.env.REACT_APP_API_PREFIX
env.storeApi = process.env.REACT_APP_STORE_API
env.googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
env.googleClientSecret = process.env.REACT_APP_CLIENT_SECRET
env.facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID
env.facebookAppSecret = process.env.REACT_APP_FACEBOOK_APP_SECRET
env.applicationStage = process.env.REACT_APP_STAGE
env.fbaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY
env.fbaseAuthDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
env.fbaseProjectID = process.env.REACT_APP_FIREBASE_PROJECTID
env.fbaseStoreBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
env.fbaseMsgID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
env.fbaseAppID = process.env.REACT_APP_FIREBASE_APP_ID
env.fbaseMeasureID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
env.fbaseVapiKey = process.env.REACT_APP_FIREBASE_USE_PUBLIC_VAPI_KEY
env.fbPixelsId = process.env.REACT_APP_FACEBOOK_PIXEL_ID

export default env
