/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Autocomplete } from '@material-ui/lab'
import { Controller } from 'react-hook-form'
import {
  FormHelperText, InputLabel, TextField, CircularProgress,
} from '@material-ui/core'
import clsx from 'clsx'
import useStyles from './styles-jss'

const InputAutocomplete = (props) => {
  const {
    name,
    register,
    api,
    errors,
    label,
    setValue,
    getOptionSelected,
    getOptionLabel,
    defaultValue,
  } = props
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  // const [selectedValue, setSelectedValue] = useState({ id: '', name: '' })
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  let timeout = 0

  const renderErrorMessage = () => (
    !!errors[name] && errors[name].message
  )

  const renderInputLabel = () => (
    label && (
    <InputLabel className={classes.inputLabel}>
      {label}
    </InputLabel>
    )
  )

  useEffect(() => {
    api()
      .then((res) => {
        const { status, data: { data } } = res
        if (status === 200) setOptions(data)
      })
  }, [])

  const onChange = (e) => {
    setLoading(true)
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(async () => {
      const { value } = e.target

      if (value) {
        try {
          const { status, data: { data } } = await api(value)
          if (status === 200) {
            setOptions(data)
            setLoading(false)
          }
        } catch (error) {
        // eslint-disable-next-line no-console
          console.warn('autocomplete: something went wrong')
        }
      }
    }, 1000)
  }

  return (
    <div className={clsx(classes.root, classes.autoCompleteRoot)}>
      {renderInputLabel()}
      <Autocomplete
        ref={register}
        name={name}
        open={open}
        // value={selectedValue}
        defaultValue={defaultValue}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        options={options}
        loading={loading}
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        onChange={(e, value, reason) => {
          if (reason === 'select-option') {
            setValue(name, value, { shouldValidate: true })
          } else if (reason === 'clear') {
            setValue(name, value, { shouldValidate: true })
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={`Cari ${label}...`}
            onChange={onChange}
            className={classes.autocomplete}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <FormHelperText className={classes.errorMessage}>{renderErrorMessage()}</FormHelperText>
    </div>
  )
}

InputAutocomplete.defaultProps = {
  errors: {},
  message: '',
  label: '',
  defaultValue: { name: '', id: '' },
}

InputAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  errors: PropTypes.object,
  message: PropTypes.string,
  label: PropTypes.any,
  setValue: PropTypes.func.isRequired,
  api: PropTypes.func.isRequired,
  getOptionSelected: PropTypes.func,
  getOptionLabel: PropTypes.func,
}

const FieldAutocomplete = (props) => {
  const {
    control, name, defaultValue,
  } = props

  return (
    <Controller
      name={name}
      control={control}
      render={(onChange, onBlur) => (
        <InputAutocomplete
          onChange={onChange}
          onBlur={onBlur}
          defaultValue={defaultValue}
          {...props}
        />
      )}
    />
  )
}

FieldAutocomplete.defaultProps = {
  defaultValues: '',
  defaultValue: { name: '', id: '' },
}

FieldAutocomplete.propTypes = {
  control: PropTypes.any.isRequired,
  name: PropTypes.any.isRequired,
  defaultValues: PropTypes.any,
  defaultValue: PropTypes.object,
}

export default FieldAutocomplete
