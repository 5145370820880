/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core'
import icons from '@assets/icons'
import React, { Fragment, useState, useEffect } from 'react'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useSnackbar } from 'notistack'
import images from '@assets/images'
import { saasApi } from '@api/saasApi'
import env from '@config/env'
import GoogleLogin from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import RadioPaymentChannels from '@components/CustomFields/RadioPaymentChannels'
import useAxios from '@hooks/useAxios'
import ReactPixel from 'react-facebook-pixel'
import useStyles from './styles-jss/register-jss'
import FormRegister from './FormRegister'
import PackageInformation from './PackageInformation'
import hookFormConfig from './hookFormConfig'

const Register = () => {
  const classes = useStyles()
  // eslint-disable-next-line no-unused-vars

  const {
    errors,
    control,
    handleSubmit,
    getValues,
    setValue,
    register,
    watch,
  } = useForm(hookFormConfig)

  const hookFormProps = {
    errors,
    control,
    getValues,
    setValue,
    register,
    watch,
  }

  const history = useHistory()
  const [isSubmitting, setSubmitting] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const paymentChannels = useAxios(
    saasApi,
    'get',
    '/api/v1/payment_channels?store_id=',
  )
  const customDomain = watch('custom_domain')

  useEffect(() => {
    paymentChannels.fetch()
    ReactPixel.init(env.fbPixelsId)
    ReactPixel.track('visit page')
  }, [])

  const onSubmit = async (data) => {
    setSubmitting(true)
    const { payment_method } = data
    const payload = {
      name: data.name,
      email: data.email,
      password: data.password,
      password_confirmation: data.password_confirmation,
      phone_number: data.phone_number,
      voucher_package_id: data?.voucher_package?.id,
      price: data?.voucher_package
        ? data?.voucher_package?.type_cd === 'percentage'
          ? (parseInt(data?.price) / 100)
            * (100 - data?.voucher_package?.amount)
          : parseInt(data?.price) - data?.voucher_package?.amount
        : data.price,
      payment_method: data.payment_method,
      affiliate_code: data.affiliate_code,
      stores_attributes: [
        {
          name: data.store_name,
          domain: customDomain
            ? `https://${data.domain}`
            : `https://${data.domain}.squids.id`,
          district_id: data.district_id.id,
          address: data.address,
          postal_code: data.postal_code,
          type_package_period: data.type_package_period,
          package_id: data.package_name,
          is_distributor: data?.is_distributor,
          total_reseller: data?.total_reseller,
          bank_accounts_attributes: [
            {
              name: data?.bank_name,
              number: data?.bank_number,
              bank_id: data?.bank_id?.id,
            },
          ],
        },
      ],
    }
    try {
      const res = await saasApi.post('api/v1/saas_users', {
        saas_user: payload,
      })
      const {
        data: { success },
      } = res
      if (success) {
        setSubmitting(false)
        localStorage.removeItem('ref')
        if (!payment_method) {
          enqueueSnackbar(
            'pendaftaran berhasil, silahkan lakukan verifikasi email',
            {
              variant: 'success',
            },
          )
          history.push('/login')
        } else if (payment_method === 'bank_transfer') {
          history.push('/manual-payment')
        } else {
          history.push(`/register-payment?email=${res.data?.data?.email}`)
        }
      } else {
        setSubmitting(false)
        const {
          data: { message },
        } = res
        if (Array.isArray(message)) {
          message.map((value) => enqueueSnackbar(value, {
            variant: 'error',
          }))
        }
      }
    } catch (error) {
      const {
        data: resData,
      } = error.response
      if (resData.data) {
        setSubmitting(false)
        enqueueSnackbar(resData.data, {
          variant: 'error',
        })
      } else {
        setSubmitting(false)
        enqueueSnackbar('Maaf, Terjadi kesalahan pada sistem', {
          variant: 'error',
        })
      }
    }
  }

  const googlResponse = (response) => {
    const { profileObj } = response
    setValue('name', profileObj.name, { shouldDirty: true })
    setValue('email', profileObj.email, { shouldDirty: true })
  }

  const payment = watch('price') !== '' && watch('price') !== '0'

  const responseFacebook = (response) => {
    // eslint-disable-next-line no-console
    const { name, email } = response
    setValue('name', name, { shouldDirty: true })
    setValue('email', email, { shouldDirty: true })
  }

  return (
    <Fragment>
      <Helmet>
        <title>Squids | Register</title>
      </Helmet>
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        className={classes.root}
      >
        <Box className={classes.background}>
          <img
            src={icons.AppLogoWhite}
            alt="logo"
            className={classes.logo}
            height={136}
            width={110}
          />
          <img
            src={images.BgRegister}
            alt="register"
            className={classes.bgImage}
          />
        </Box>
        <Box className={classes.content}>
          <Box className={classes.column}>
            <hr className={clsx(classes.divider, classes.dividerTop)} />
            <Box className={classes.sectionSocialLogin}>
              <Typography className={classes.txRegisterWith}>
                Daftar Dengan:
              </Typography>
              <Box className={classes.socialIconWrapper}>
                <GoogleLogin
                  clientId={env.googleClientId}
                  onSuccess={googlResponse}
                  onFailure={googlResponse}
                  cookiePolicy="single_host_origin"
                  render={(renderProps) => (
                    <IconButton size="medium" onClick={renderProps.onClick}>
                      <img
                        src={images.GoogleLogo}
                        alt="google"
                        className={classes.socialLogo}
                      />
                    </IconButton>
                  )}
                />
                <FacebookLogin
                  appId={env.facebookAppId}
                  callback={responseFacebook}
                  fields="name, email, picture"
                  scope="public_profile"
                  render={(renderProps) => (
                    <IconButton size="medium" onClick={renderProps.onClick}>
                      <img
                        src={images.FacebookLogo}
                        alt="facebook"
                        className={classes.socialLogo}
                      />
                    </IconButton>
                  )}
                />
              </Box>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ alignSelf: 'center', margin: 'auto' }}
              >
                Punya Akun?{' '}
                <Link
                  to="/login"
                  style={{ color: '#556FB5', fontWeight: 'bold' }}
                >
                  Masuk
                </Link>
              </Typography>
            </Box>
            <input hidden name="provider" ref={register} />

            <FormRegister
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              setSubmitting={setSubmitting}
              errored={errors}
              {...hookFormProps}
            />
          </Box>

          <Box className={classes.column}>
            <input
              type="text"
              ref={register}
              name="type_package_period"
              hidden
            />
            <PackageInformation
              watch={watch}
              register={register}
              setValue={setValue}
              errors={errors}
              getValues={getValues}
              control={control}
              {...hookFormProps}
            />
            {payment && (
              <RadioPaymentChannels
                items={paymentChannels.data}
                name="payment_method"
                label="Metode Pembayaran"
                errors={errors}
                disabled={isSubmitting}
                control={control}
              />
            )}
          </Box>

          <Paper className={classes.footer}>
            <Button
              type="submit"
              className={classes.btnSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                'Daftar Sekarang'
              )}
            </Button>
          </Paper>
        </Box>
      </form>
    </Fragment>
  )
}

export default Register
