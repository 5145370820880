import React from 'react'
import PropTypes from 'prop-types'
import { Box, CircularProgress } from '@material-ui/core'

const LoadingApi = ({ loading, ...props }) => (
  loading && <Box display="flex" justifyContent="center" width="100%"><CircularProgress {...props} /> </Box>
)

LoadingApi.propTypes = {
  loading: PropTypes.bool.isRequired,
}

export default LoadingApi
