/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import {
  Backdrop,
  Box, Button, CircularProgress, Paper, Typography,
} from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Helmet } from 'react-helmet'
import dayjs from 'dayjs'
import 'dayjs/locale/id'
import icons from '@assets/icons'
import useQuery from '@hooks/useQuery'
import { saasApi } from '@api/saasApi'
import images from '@assets/images'
import env from '@config/env'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import ReactPixel from 'react-facebook-pixel'
import useStyles from './styles-jss/registerPayment-jss'

const RegisterPayment = () => {
  const classes = useStyles()
  const query = useQuery()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState([])
  const [banks, setBanks] = useState([])
  const [retailOutlets, setRetailOutlets] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [wallets, setWallets] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const [isExpired, setIsExpired] = useState(false)
  const [paymentDueDate, setPaymentDueDate] = useState('')
  const [messages, setMessages] = useState('')
  const [pixelValue, setPixelValue] = useState()

  useEffect(() => {
    dayjs.locale('id')
    const checkPayment = async () => {
      try {
        setLoading(true)
        const { data: { data, status } } = await saasApi
          .get(`/api/v1/saas_systems/subscriptions?email=${query.get('email')}`)

        if (status === 'ok') {
          setPixelValue(data?.invoice)
          setLoading(false)
          setPayload(data)
          setWallets(data.invoice?.available_ewallets || [])
          setBanks(data.invoice?.available_banks || [])
          setRetailOutlets(data.invoice?.available_retail_outlets || [])
          setIsExpired(dayjs(data.invoice?.expired_at) < dayjs(new Date()))
          setPaymentDueDate(dayjs(data.invoice?.expired_at).format('DD MMMM YYYY Pukul HH:mm'))

          setMessages(
            `Halo tim Squids, saya mau mengkonfirmasi pembayaran dengan rincian sebagai berikut : %0a
%0a
Nama : ${data.user_account_name} %0a
Nama Toko : ${data.store_name} %0a
Domain : ${data.domain} %0a

Paket : ${data.package_name} %0a
Periode : ${data.date_period} %0a
Total pembayaran : ${data.invoice?.formated_total} %0a
Metode pembayaran : ${data.payment_method_name}%0a
Detail Pembayaran : ${data.detail_payment} %0a
%0a
Terima Kasih`,
          )
        } else {
          enqueueSnackbar(data, {
            variant: 'error',
          })
          setTimeout(() => {
            window.location = '/'
          }, 3000)
        }
      } catch (error) {
        setLoading(false)
      }
    }

    if (query.get('email') === null) {
      history.goBack()
    } else {
      checkPayment()
    }

    ReactPixel.init(env.fbPixelsId)
    ReactPixel.track('add payment info')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.get('email')])

  const renderExpired = () => (
    <Paper className={classes.paper}>
      <img
        src={icons.AppLogo}
        height={138}
        width={110}
        alt="logo"
        className={classes.logo}
      />
      <Typography
        variant="h6"
        className={classes.expiredText}
        gutterBottom
      >
        Maaf, Pembayaran telah kedaluarsa,
        silahkan lakukan registrasi ulang atau hubungi Customer Service
      </Typography>
      <Button
        color="primary"
        style={{ marginTop: 16 }}
        variant="contained"
        component={Link}
        to="/"
      >
        Kembali ke halaman awal
      </Button>
    </Paper>
  )

  const sendWhatsapp = () => {
    ReactPixel.track('upload bukti bayar', pixelValue)
    const phoneNumber = '6281221566495'
    window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${messages}`, '_blank')
  }

  const renderActivePayment = () => (
    <div className={classes.wrapper}>
      <Paper className={classes.paper}>

        <img
          src={icons.AppLogo}
          height={138}
          width={110}
          alt="logo"
          className={classes.logo}
        />

        <Typography
          variant="h6"
          className={classes.title}
          gutterBottom
        >
          Terima kasih telah melakukan registrasi
          dengan Paket {payload?.package_name}
        </Typography>

        <Typography
          variant="body2"
          className={classes.txDescription}
          gutterBottom
        >
          Silahkan lakukan pembayaran sebelum
        </Typography>

        <Typography
          variant="body2"
          className={classes.txDescription}
          gutterBottom
        >
          <strong> {paymentDueDate} </strong>
        </Typography>

        <Typography
          variant="body2"
          className={classes.txDescription}
          gutterBottom
        >
          Untuk menyelesaikan proses registrasi, dan transfer sejumlah:
        </Typography>

        <Typography
          variant="body2"
          className={classes.price}
          gutterBottom
        >
          {payload.invoice?.formated_total}
        </Typography>

        <Paper className={classes.info}>
          <strong>Penting! </strong>
          Mohon transfer sesuai sampai dengan 3 digit terakhir
        </Paper>

        <Typography variant="caption" gutterBottom>
          {
            (banks.length >= 1)
              ? 'Silahkan Transfer ke salah satu bank berikut:'
              : 'Silakan lakukan pembayaran menggunakan link dibawah ini:'
          }
        </Typography>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {
            (banks.length >= 1) && banks.map((item) => (
              <Box display="flex" alignItems="flex-start" key={item.bank_code}>
                <Box
                  margin={2}
                  height={64}
                  width={64}
                >
                  {item.icon && (
                  <img
                    src={item.icon}
                    alt={item.ewallet_type}
                    height={64}
                    width={64}
                  />
                  )}
                </Box>
                <Box margin={2} display="flex" flexDirection="column" flex={1}>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: '#1C1977',
                    }}
                  >
                    {item.bank_code}
                  </Typography>
                  <Typography style={{ fontSize: 14 }}>
                    {item.account_holder_name}
                  </Typography>
                  <Typography style={{ fontSize: 14 }}>
                    {item.bank_account_number}
                  </Typography>
                </Box>
              </Box>
            ))
          }
        </Box>
        {
          wallets.length > 0
            && (
            <Typography variant="caption" gutterBottom>
              Pembayaran melalui e-Wallet:
            </Typography>
            )
        }
        <Box display="flex" flexDirection="column">
          {
            wallets.map((item) => (
              <Box
                display="flex"
                alignItems="flex-start"
                key={item.ewallet_type}
              >
                <Box
                  margin={2}
                  height={64}
                  width={64}
                >
                  {item.icon && (
                  <img
                    src={item.icon}
                    alt={item.ewallet_type}
                    height={64}
                    width={64}
                  />
                  )}
                </Box>
                <Box
                  margin={2}
                  display="flex"
                  flexDirection="column"
                  flex={1}
                >
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: '#1C1977',
                    }}
                  >
                    {item.ewallet_type}
                  </Typography>
                </Box>
              </Box>
            ))
          }
        </Box>

        {
          retailOutlets.length > 0
            && (
            <Typography variant="caption" gutterBottom>
              Pembayaran melalui Retail Outlets:
            </Typography>
            )
        }
        <Box display="flex" flexDirection="column">
          {
            retailOutlets.map((item) => (
              <Box
                display="flex"
                alignItems="flex-start"
                key={item.retail_outlet_name}
              >
                <Box
                  margin={2}
                  height={64}
                  width={64}
                >
                  {item.icon && (
                  <img
                    src={item.icon}
                    alt={item.ewallet_type}
                    height={64}
                    width={64}
                  />
                  )}
                </Box>
                <Box
                  margin={2}
                  display="flex"
                  flexDirection="column"
                  flex={1}
                >
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: '#1C1977',
                    }}
                  >
                    {item.retail_outlet_name}
                  </Typography>
                </Box>
              </Box>
            ))
          }
        </Box>
        {
          payload?.invoice?.payment_url && (env.applicationStage === 'development' || env.applicationStage === 'staging') && (
            <Button
              color="primary"
              onClick={() => window.open(payload.invoice.payment_url)}
            >
              Klik Disini Untuk Pembayaran
            </Button>
          )
        }
        <Button
          color="primary"
          style={{ marginTop: 16 }}
          variant="contained"
          component={Link}
          to="/"
        >
          Kembali ke halaman awal
        </Button>

        <Typography
          variant="body2"
          style={{ marginTop: 15 }}
          className={classes.txDescription}
          gutterBottom
        >
          Upload bukti pembayaran anda ke nomor berikut
        </Typography>
        <Button
          color="primary"
          style={{ marginTop: 16, backgroundColor: '#5BA737' }}
          variant="contained"
          onClick={sendWhatsapp}
          startIcon={<WhatsAppIcon />}
        >
          +62 812-2156-6495
        </Button>
      </Paper>
    </div>
  )

  return (
    <Box className={classes.root}>
      <Helmet>
        <title>Squids Payment</title>
      </Helmet>
      <img
        src={images.BgTree}
        alt="tree"
        height="auto"
        width="100%"
        className={classes.bgImage}
      />
      <Backdrop
        open={loading}
        className={classes.backdrop}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
      {
        isExpired ? renderExpired() : renderActivePayment()
      }
    </Box>
  )
}

export default RegisterPayment
