import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  txForgotPassword: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 10,
  },
  txDescription: {
    marginBottom: 27,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  form: {
    display: 'flex',
    width: 520,
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '90%',
    },
  },
  btnSubmit: {
    width: 120,
    height: 45,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export default useStyles
