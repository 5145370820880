/* eslint-disable react/prop-types */
import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import React, { useState, Fragment } from 'react'
import { Controller } from 'react-hook-form'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import StoreIcon from '@material-ui/icons/Store'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  txLabel: {
    fontWeight: 'bold',
    fontSize: 20,
    color: 'black',
    marginBottom: theme.spacing(2),
  },
  radioGroup: {
    marginTop: theme.spacing(1),
  },
  childWrapper: {
    display: 'flex',
    backgroundColor: '#E6EDF2',
    padding: theme.spacing(1),
    justifyContent: 'start',
  },
  root: {
    backgroundColor: '#F6F9FB',
    padding: theme.spacing(1),
    width: '95%',
  },
  error: {
    border: '0.5px solid red',
  },
  txError: {
    color: 'red',
  },
  container: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    margin: '0 auto',
    width: '100%',
    marginTop: theme.spacing(2),
    backgroundColor: '#F6F9FB',
    padding: theme.spacing(1),
  },
  nested: {
    marginLeft: theme.spacing(9),
  },
  title: {
    textTransform: 'capitalize',
    flex: 1,
  },
  listItem: {
    display: 'flex',
  },
}))

const RadioPaymentChannels = (props) => {
  const {
    items, control, errors, label, name,
  } = props
  const classes = useStyles()
  const [collapse, setCollapse] = useState()
  const renderCustomLabel = (icon, radioLabel) => (
    <Box
      display="flex"
      alignItems="center"
    >
      <img src={icon} height={32} width={32} style={{ objectFit: 'contain' }} alt={radioLabel} />
      <Typography variant="body1" style={{ marginLeft: 8, width: '100%' }}>
        {radioLabel}
      </Typography>
    </Box>
  )

  return (
    <Controller
      control={control}
      name={name}
      render={({
        onChange, onBlur,
      }) => (
        <div className={classes.container}>
          <FormLabel className={classes.txLabel}>
            {label}
          </FormLabel>
          <FormControl
            onChange={onChange}
            onBlur={onBlur}
            component="fieldset"
            className={clsx(classes.root, { [classes.error]: errors && !!errors[name] })}
          >
            <RadioGroup aria-label={label} {...props} className={classes.radioGroup}>
              <List>
                {!items
                  && (
                  <Box display="flex" flexDirection="column">
                    <Skeleton width="100%" height={55} />
                    <Skeleton width="100%" height={55} />
                  </Box>
                  )}
                {
                  items && Object.keys(items).map((key) => (
                    items[key].length > 0 && (
                    <Fragment key={key}>
                      <ListItem
                        button
                        onClick={() => setCollapse(collapse === key ? null : key)}
                        className={classes.listItem}
                      >
                        <ListItemIcon>
                          { key === 'BANK_TRANSFER' && <AccountBalanceIcon color="primary" />}
                          { key === 'VIRTUAL_ACCOUNT' && <CreditCardIcon color="primary" />}
                          { key === 'EWALLET' && <AccountBalanceWalletIcon color="primary" />}
                          { key === 'RETAIL_OUTLET' && <StoreIcon color="primary" />}
                        </ListItemIcon>
                        <Typography
                          className={classes.title}
                        >
                          {key.replace('_', ' ')}
                        </Typography>
                        {collapse === key ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={collapse === key}>
                        <List className={classes.nested}>
                          {
                            items[key].map((item) => (
                              <FormControlLabel
                                key={item.code}
                                style={{ marginTop: 8, width: '100%' }}
                                value={key === 'BANK_TRANSFER' ? `BANK_TRANSFER_${item?.account}` : item.code}
                                control={(
                                  <Radio
                                    color="primary"
                                  />
                              )}
                                label={renderCustomLabel(item.icon, `${item.name} ${item.account ? ` - ${item.account}` : ''}`)}
                              />
                            ))
                          }
                        </List>
                      </Collapse>
                    </Fragment>
                    )
                  ))
                }
              </List>
            </RadioGroup>
            <FormHelperText
              className={classes.txError}
            >
              {errors && errors[name]?.message}
            </FormHelperText>
          </FormControl>
        </div>
      )}
    />
  )
}

export default RadioPaymentChannels
