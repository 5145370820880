/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */
/* eslint-disable radix */
/* eslint-disable camelcase */
import { saasApiWithToken } from '@api/saasApi'

const path = 'saas_systems/notifications'

export const getCollectionApi = (params) => saasApiWithToken.get(path, { params })
export const updateApi = (id) => saasApiWithToken.put(`${path}/${id}`)
