import { makeStyles } from '@material-ui/core'
import { appbarHeight, drawerWidth } from '@themes/constant'

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  toolbar: {
    height: appbarHeight,
    borderRadius: 0,
    display: 'flex',
    alignContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 0,
    },
  },
  appBarRoot: {
    borderRadius: 0,
    backgroundColor: '#F5F5F5',
  },
  menuIcon: {
    color: theme.palette.primary.main,
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  hide: {
    display: 'none',
    transition: theme.transitions.create(['display'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      visibility: 'hidden',
      transition: theme.transitions.create(['visibility'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  menuTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    flex: 1,
    marginLeft: theme.spacing(1),
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  toolbarAction: {
    display: 'flex',
    alignItems: 'center',
  },
  chip: {
    height: 38,
    backgroundColor: '#ECF2F5',
    textTransform: 'capitalize',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  iconButton: {
    margin: '0px 8px 0px 8px',
    '&.MuiIconButton-root': {
      backgroundColor: '#ECF2F5',
    },
  },
  inputSearch: {
    backgroundColor: '#ECF2F5',
    borderRadius: 50,
    padding: theme.spacing(1),
    width: 275,
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  notification: {
    width: 340,
    height: 'auto',
    borderRadius: 5,
    marginTop: 60,
    marginLeft: -150,
    border: 'solid 0.5px #cccccc',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      backgroundColor: 'RGBA(85,111,181,0.6)',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    },
  },
  listItem: {
    position: 'relative',
  },
  listItemUnread: {
    position: 'relative',
    backgroundColor: '#F5F5F5',
  },
  secondaryText: {
    '&.MuiTypography-root': {
      '&.MuiListItemText-secondary': {
        color: 'red',
      },
    },
  },
  notificationTime: {
    position: 'absolute',
    bottom: 8,
    right: 16,
    fontSize: 12,
    color: 'grey',
  },
  userMenu: {
    width: 'auto',
    height: 'auto',
    marginTop: 60,
    marginLeft: 'auto',
  },
}))

export default useStyles
