/* eslint-disable react/prop-types */
import React from 'react'
import {
  Box,
  FormHelperText,
  InputBase,
  InputLabel,
} from '@material-ui/core'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import useStyles from './styles-jss'

const InputTextVoucher = (props) => {
  const classes = useStyles()
  const {
    disabled, errors, name, label, setVoucherCode, styleBox,
  } = props

  const renderErrorMessage = () => (
    !!errors[name] && errors[name].message
  )

  const renderFieldLabel = () => (
    label && (
    <InputLabel className={classes.inputLabel}>
      {label}
    </InputLabel>
    )
  )

  return (
    <Box className={classes.root} style={styleBox}>
      {renderFieldLabel()}
      <InputBase
        {...props}
        className={clsx(classes.field, {
          [classes.error]: !!errors[name],
        })}
        disabled={disabled}
        type="text"
        inputProps={{
          style: { marginLeft: 16, marginRight: 16 },
        }}
        onChange={(e) => {
          setVoucherCode(e?.target?.value)
        }}
      />
      <FormHelperText
        className={classes.errorMessage}
      >
        {renderErrorMessage()}
      </FormHelperText>
    </Box>
  )
}

InputTextVoucher.defaultProps = {
  errors: {},
  label: undefined,
}

InputTextVoucher.propTypes = {
  errors: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  setVoucherCode: PropTypes.func.isRequired,
  label: PropTypes.string,
}

const FieldTextVoucher = ({
  label,
  placeholder,
  errors,
  isSubmitting,
  register,
  name,
  setVoucherCode,
  styleBox,
}) => (
  <InputTextVoucher
    name={name}
    disabled={isSubmitting}
    errors={errors}
    inputRef={register}
    placeholder={placeholder}
    label={label}
    setVoucherCode={setVoucherCode}
    styleBox={styleBox}
  />
)

FieldTextVoucher.defaultProps = {
  errors: {},
  placeholder: '',
  label: '',
}

FieldTextVoucher.propTypes = {
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  register: PropTypes.func.isRequired,
  setVoucherCode: PropTypes.func.isRequired,
}

export default FieldTextVoucher
