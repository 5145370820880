import React, {
  createContext,
  useEffect,
  useState,
  useReducer,
} from 'react'
import PropTypes from 'prop-types'
import { Backdrop, makeStyles } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Sidebar from '@components/Sidebar'
import Header from '@components/Header'
import useWidth from '@hooks/useWidth'
import application from '@config/application'
import { breadcrumbs } from '@routes/menu'
import { notifInitial, notifReducer } from '@reducer/notification'

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: '#F5F5F5',
    marginTop: 84,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 300,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  backdrop: {
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}))

export const AdminContext = createContext(null)

export const AdminProvider = ({ children }) => {
  const classes = useStyles()
  const width = useWidth()
  const { pathname } = useLocation()

  const [pageTitle, setPageTitle] = useState(application.name)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [state, dispatch] = useReducer(notifReducer, notifInitial)

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  useEffect(() => {
    if (width === 'xs' || width === 'sm') {
      setDrawerOpen(false)
    } else {
      setDrawerOpen(true)
    }
  }, [width])

  useEffect(() => {
    const path = pathname.replace(application.path, '')
    const split = path.split('/')
    const last = split[split.length - 1]
    setDrawerOpen(drawerOpen)
    setPageTitle(breadcrumbs()[path] || last)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen, pathname])

  const value = {
    pageTitle,
    setPageTitle,
    drawerOpen,
    setDrawerOpen,
    state,
    dispatch,
  }

  const uppercase = (title) => title.charAt(0).toUpperCase() + title.slice(1)

  return (
    <AdminContext.Provider value={value}>
      <CssBaseline />
      <Helmet>
        <title>{application.name} - {uppercase(pageTitle)}</title>
      </Helmet>
      <Header open={drawerOpen} toggleDrawer={toggleDrawer} pageTitle={uppercase(pageTitle)} />
      <Sidebar open={drawerOpen} toggleDrawer={toggleDrawer} />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <Backdrop
          open={drawerOpen}
          onClick={toggleDrawer}
          className={classes.backdrop}
        />
        {children}
      </main>
    </AdminContext.Provider>
  )
}

AdminProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
