import { Button, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import icons from '@assets/icons'
import PageHandler from '@layout/PageHandler'

const useStyles = makeStyles((theme) => ({
  text: {
    color: '#1A253B',
    fontSize: 20,
    fontWeight: 'bold',
    margin: '16px 0px 16px 0px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const NotFound = () => {
  const classes = useStyles()
  const history = useHistory()

  const handleClick = () => {
    history.goBack()
  }
  return (
    <PageHandler pageTitle="404 Not Found">
      <img
        src={icons.WomanWithLaptopIcon}
        alt="woman"
        className={classes.img}
        height={280}
        width={240}
      />
      <Typography variant="h1" className={classes.text}>
        Halaman yang Anda cari tidak ditemukan
      </Typography>
      <Button
        className={classes.link}
        onClick={handleClick}
      >
        Kembali ke Halaman Sebelumnya
      </Button>
    </PageHandler>
  )
}
export default NotFound
