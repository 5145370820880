export const notifInitial = {
  notifList: [],
}

export const notifReducer = (state, { type, value }) => {
  switch (type) {
    case 'refreshNotif':
      return { notifList: value }
    case 'getNotif':
      return state
    default:
      throw new Error()
  }
}
