import { Button, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import icons from '@assets/icons'
import images from '@assets/images'
import useQuery from '@hooks/useQuery'
import useStyles from './styles-jss/registerSuccess-jss'

const RegisterSuccess = () => {
  const classes = useStyles()
  const query = useQuery()
  const history = useHistory()

  useEffect(() => {
    if (query.get('name') === null) history.push('/')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.get('name')])

  return (
    <div className={classes.root}>
      <img
        src={icons.AppLogo}
        alt="logo"
        height={110}
        width={136}
        style={{ marginTop: 100 }}
      />
      <Typography
        variant="h1"
        color="primary"
        className={classes.title}
        gutterBottom
      >
        Pendaftaran Berhasil!
      </Typography>
      <Typography
        variant="h1"
        className={classes.title2}
        gutterBottom
      >
        Selamat menggunakan
        <span className={classes.appTitle}> App Squids</span>, {query.get('name')}!
      </Typography>

      <Typography
        variant="caption"
        className={classes.description}
        gutterBottom
      >
        Mohon periksa inbox di <strong>{query.get('email')}</strong> dan lakukan verifikasi Email untuk melanjutkan.
      </Typography>

      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/"
      >
        Masuk ke dashboard
      </Button>

      <Typography
        className={classes.resendEmail}
        component={Link}
        to="#"
        gutterBottom
      >
        Tidak menerima email? <span className={classes.txResend}>Kirim Ulang</span>
      </Typography>

      <img
        src={images.BgTreeSmall}
        alt="tree"
        width={370}
        height={190}
        className={classes.background}
      />
    </div>
  )
}

export default RegisterSuccess
