import React, { Fragment } from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = !!localStorage.getItem('token')
  return (
    <Fragment>
      <Route
        {...rest}
        render={
          (props) => (isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />)
        }
      />
    </Fragment>
  )
}

PrivateRoute.propTypes = ({
  component: PropTypes.any.isRequired,
})

export default PrivateRoute
