import React, { useState } from 'react'
import {
  Box,
  FormHelperText,
  IconButton,
  InputBase,
  InputLabel,
} from '@material-ui/core'
import { Controller } from 'react-hook-form'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import icons from '@assets/icons'
import useStyles from './styles-jss'

const InputTextPassword = (props) => {
  const classes = useStyles()
  const [visible, setVisible] = useState(false)
  const {
    disabled, errors, name, label,
  } = props

  const onVisibilityClick = () => setVisible(!visible)

  const renderErrorMessage = () => (
    !!errors[name] && errors[name].message
  )

  const renderFieldLabel = () => (
    label && (
    <InputLabel className={classes.inputLabel}>
      {label}
    </InputLabel>
    )
  )

  return (
    <Box className={classes.root}>
      {renderFieldLabel()}
      <InputBase
        {...props}
        className={clsx(classes.field, {
          [classes.error]: !!errors[name],
        })}
        disabled={disabled}
        type={visible ? 'text' : 'password'}
        inputProps={{
          style: { marginLeft: 16, marginRight: 16 },
        }}
        startAdornment={(
          <img
            src={icons.LockIcon}
            alt="email"
            height={26}
            width={26}
          />
        )}
        endAdornment={(
          <IconButton
            size="small"
            onClick={onVisibilityClick}
            disabled={disabled}
          >
            <img
              src={icons.VisibilityIcon}
              alt="visibility"
              height={26}
              width={26}
            />
          </IconButton>
      )}
      />
      <FormHelperText
        className={classes.errorMessage}
      >
        {renderErrorMessage()}
      </FormHelperText>
    </Box>
  )
}

InputTextPassword.defaultProps = {
  errors: {},
  label: undefined,
}

InputTextPassword.propTypes = {
  errors: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

const FieldTextPassword = ({
  label,
  placeholder,
  errors,
  isSubmitting,
  register,
  ...props
}) => (
  <Controller
    {...props}
    render={({
      onChange, onBlur,
    }) => (
      <InputTextPassword
        name={props.name}
        onBlur={onBlur}
        onChange={onChange}
        disabled={isSubmitting}
        errors={errors}
        inputRef={register}
        placeholder={placeholder}
        label={label}
      />
    )}
  />
)

FieldTextPassword.defaultProps = {
  errors: {},
  placeholder: '',
  label: '',
}

FieldTextPassword.propTypes = {
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  register: PropTypes.func.isRequired,
}

export default FieldTextPassword
