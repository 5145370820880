import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'fixed',
    height: '100%',
    width: '100%',
  },
  background: {
    width: '60vw',
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    /* boxShadow: '5px 5px 0px 0px rgba(204,48,201,1);',
    '-webkit-box-shadow': ' 5px 5px 5px 0px rgba(54,48,201,1);',
    '-moz-box-shadow': ' 5px 5px 5px 0px rgba(54,48,201,1);', */
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  formContainer: {
    width: '40vw',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  shape: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    maxWidth: '60vw',
    bottom: 0,
  },
  logo: {
    marginBottom: 28,
  },
  title: {
    fontWeight: 600,
    marginBottom: 20,
    fontSize: 20,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: 380,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  forgotPassword: {
    fontWeight: 'bold',
    textDecoration: 'none',
    margin: '10px 0px 10px 0px ',
  },
  socialIcon: {
    height: 26,
    width: 26,
    margin: theme.spacing(1),
  },
  txRegister: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  txNoAccount: {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    marginTop: theme.spacing(4),
  },
  txFooter: {
    color: theme.palette.text.secondary,
    position: 'absolute',
    bottom: 52,
    textAlign: 'center',
  },
  version: {
    color: theme.palette.text.secondary,
    position: 'absolute',
    right: 20,
    bottom: 20,
  },
}))

export default useStyles
