import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'
import application from '../config/application'
import NotFound from '../containers/Pages/NotFound'
// import asyncPage from '../containers/Pages/asyncPage'
import {
  Dashboard,
  Account,
  ChangePassword,
  Store,
  User,
  EditUser,
  Integration,
  Moota,
  FacebookPixels,
  GoogleAnalytics,
  Woowa,
  TawkTo,
  PaymentGateway,
  Shipment,
  Billing,
  Support,
  Notification,
  Balance,
} from '../containers/Pages/asyncPage'
import { AdminProvider } from '../containers/Layout/AdminProvider'

const AdminRoutes = ({ history }) => (
  <AdminProvider history={history}>
    <Switch>
      <PrivateRoute path={`${application.path}/dashboard`} component={Dashboard} />
      <PrivateRoute path={`${application.path}/account`} component={Account} />
      <PrivateRoute path={`${application.path}/change-password`} component={ChangePassword} />
      <PrivateRoute path={`${application.path}/list-store`} component={Store} />
      <PrivateRoute exact path={`${application.path}/users`} component={User} />
      <PrivateRoute path={`${application.path}/users/:id`} component={EditUser} />
      <PrivateRoute exact path={`${application.path}/integration`} component={Integration} />
      <PrivateRoute path={`${application.path}/integration/moota`} component={Moota} />
      <PrivateRoute path={`${application.path}/integration/facebook-pixels`} component={FacebookPixels} />
      <PrivateRoute path={`${application.path}/integration/google-analytics`} component={GoogleAnalytics} />
      <PrivateRoute path={`${application.path}/integration/woowa`} component={Woowa} />
      <PrivateRoute path={`${application.path}/integration/tawkto`} component={TawkTo} />
      <PrivateRoute path={`${application.path}/integration/payment-gateway`} component={PaymentGateway} />
      <PrivateRoute path={`${application.path}/integration/shipment`} component={Shipment} />
      <PrivateRoute path={`${application.path}/billing`} component={Billing} />
      <PrivateRoute path={`${application.path}/support`} component={Support} />
      <PrivateRoute path={`${application.path}/notification`} component={Notification} />
      <PrivateRoute path={`${application.path}/balance`} component={Balance} />
      <PrivateRoute component={NotFound} />
    </Switch>
  </AdminProvider>
)
AdminRoutes.propTypes = {
  history: PropTypes.any.isRequired,
}

export default AdminRoutes
