/* eslint-disable import/prefer-default-export */
import loadable from '@loadable/component'
import React from 'react'
import LoadingPage from '@components/LoadingPage'

const loading = <LoadingPage />

// const asyncPage = (page) => loadable(() => import(`./${page}`), {
//   fallback: loading,
// })

// export default asyncPage

export const Dashboard = loadable(() => import('./Admin/Dashboard'), {
  fallback: loading,
})

export const Account = loadable(() => import('./Admin/Account'), {
  fallback: loading,
})

export const ChangePassword = loadable(() => import('./Admin/ChangePassword'), {
  fallback: loading,
})

export const Store = loadable(() => import('./Admin/Store'), {
  fallback: loading,
})

export const User = loadable(() => import('./Admin/User'), {
  fallback: loading,
})

export const EditUser = loadable(() => import('./Admin/User/EditUser'), {
  fallback: loading,
})

export const Integration = loadable(() => import('./Admin/Integration'), {
  fallback: loading,
})

export const Moota = loadable(() => import('./Admin/Integration/Moota'), {
  fallback: loading,
})

export const FacebookPixels = loadable(() => import('./Admin/Integration/FacebookPixels'), {
  fallback: loading,
})

export const GoogleAnalytics = loadable(() => import('./Admin/Integration/GoogleAnalytics'), {
  fallback: loading,
})

export const Woowa = loadable(() => import('./Admin/Integration/Woowa'), {
  fallback: loading,
})

export const TawkTo = loadable(() => import('./Admin/Integration/TawkTo'), {
  fallback: loading,
})

export const PaymentGateway = loadable(() => import('./Admin/Integration/PaymentGateway'), {
  fallback: loading,
})

export const Shipment = loadable(() => import('./Admin/Integration/Shipment'), {
  fallback: loading,
})

export const Billing = loadable(() => import('./Admin/Billing'), {
  fallback: loading,
})

export const Support = loadable(() => import('./Admin/Support'), {
  fallback: loading,
})

export const Notification = loadable(() => import('./Admin/Notification'), {
  fallback: loading,
})

export const Balance = loadable(() => import('./Admin/Balance'), {
  fallback: loading,
})
