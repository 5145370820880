import PropTypes from 'prop-types'
import React from 'react'
import NumberFormat from 'react-number-format'

const RupiasFormat = ({ value, ...props }) => (
  <NumberFormat
    value={value}
    thousandSeparator="."
    decimalSeparator=","
    prefix="Rp. "
    {...props}
  />
)

RupiasFormat.propTypes = {
  value: PropTypes.any.isRequired,
}

export default RupiasFormat
