/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import React from 'react'
import { Controller } from 'react-hook-form'
import {
  InputLabel, Grid, Typography, Box, FormHelperText,
} from '@material-ui/core'
import useStyles from './styles-jss'

function FieldCheckbox({
  register,
  control,
  name,
  component,
  getValues,
  setValue,
  defaultValues,
  label,
  errors,
  ...props
}) {
  const classes = useStyles()
  const [check, setCheck] = React.useState(null)

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      render={({ onBlur }) => (
        <React.Fragment>
          <div style={{ marginTop: 16 }}>
            <InputLabel className={classes.inputLabel}>{label}</InputLabel>
            <Grid container>
              <Grid item xs={2}>
                <Box
                  onClick={() => {
                    setCheck(true)
                    setValue('is_distributor', true, { shouldValidate: true })
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <input
                    type="checkbox"
                    style={{
                      display: 'block',
                      width: 'auto',
                      position: 'unset',
                    }}
                    checked={check !== null && check}
                    onBlur={onBlur}
                  />
                  <Typography variant="caption" style={{ marginRight: 8 }}>
                    Ya
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  onClick={() => {
                    setCheck(false)
                    setValue('is_distributor', false, { shouldValidate: false })
                  }}
                  style={{
                    display: 'flex',
                    cursor: 'pointer',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type="checkbox"
                    style={{
                      display: 'block',
                      width: 'auto',
                      position: 'unset',
                    }}
                    onBlur={onBlur}
                    checked={check !== null && !check}
                  />
                  <Typography variant="caption" style={{ marginRight: 8 }}>
                    Tidak
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <FormHelperText style={{ color: 'red' }}>
              {errors && errors.is_distributor?.message}
            </FormHelperText>
          </div>
        </React.Fragment>
      )}
    />
  )
}

export default FieldCheckbox
