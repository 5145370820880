import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    position: 'fixed',
  },
  background: {
    position: 'absolute',
    bottom: 0,
  },
  title: {
    fontSize: 36,
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 40,
  },
  title2: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'black',
  },
  appTitle: {
    color: theme.palette.primary.main,
  },
  description: {
    fontSize: 14,
    color: 'black',
    width: 380,
    textAlign: 'center',
    marginBottom: 32,
  },
  resendEmail: {
    color: 'grey',
    textDecoration: 'none',
    fontSize: 14,
    marginTop: 32,
  },
  txResend: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

export default useStyles
