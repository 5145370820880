/* eslint-disable no-console */
import firebase from 'firebase/app'
import 'firebase/messaging'
import env from './config/env'

const firebaseConfig = {
  apiKey: 'AIzaSyBTWNpPO-GcjbvxwrLbjnR5zIxUyQZgJjQ',
  authDomain: 'arctic-acolyte-298206.firebaseapp.com',
  projectId: 'arctic-acolyte-298206',
  storageBucket: 'arctic-acolyte-298206.appspot.com',
  messagingSenderId: '476117962980',
  appId: '1:476117962980:web:7933de8f9baa5b24545cf1',
  measurementId: 'G-YELCVCPR8X',
}
// const firebaseConfig = {
//   apiKey: env.fbaseApiKey,
//   authDomain: env.fbaseAuthDomain,
//   projectId: env.fbaseProjectID,
//   storageBucket: env.fbaseStoreBucket,
//   messagingSenderId: env.fbaseMsgID,
//   appId: env.fbaseAppID,
//   measurementId: env.fbaseMeasureID,
// }
export default firebase.initializeApp(firebaseConfig)

let messaging = null

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging()
}

export const getToken = (setTokenFound) => {
  if (messaging) {
    messaging
      .getToken({ vapidKey: env.fbaseVapiKey })
      .then((currentToken) => {
        if (currentToken) setTokenFound(currentToken)
      })
      .catch((err) => err)
  }
}

export const onMessageListener = () => new Promise((resolve) => {
  if (messaging) {
    messaging.onMessage((payload) => {
      resolve(payload)
    })
  }
})
