import axios from 'axios'
import env from '@config/env'
import { handleUnatuhorized, setBearerToken } from './axios.interceptors'

export const saasApi = axios.create({
  baseURL: env.saasApi,
})

export const saasApiWithToken = axios.create({
  baseURL: env.saasApi + env.apiPrefix,
})

saasApiWithToken.interceptors.request.use(setBearerToken, (err) => Promise.reject(err))
saasApiWithToken.interceptors.response.use((res) => res, handleUnatuhorized)
