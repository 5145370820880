import { makeStyles } from '@material-ui/core'
import { pallete } from '@themes/colors/squids'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    width: 490,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  txDescription: {
    width: 320,
    fontSize: 14,
    textAlign: 'center',
  },
  info: {
    backgroundColor: pallete.lightPink,
    padding: theme.spacing(1),
    color: 'black',
    borderRadius: 5,
    fontSize: 14,
    marginBottom: theme.spacing(2),
  },
  price: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: 24,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    overflow: 'auto',
    width: '100%',
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      '&::-webkit-scrollbar': {
        width: 8,
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 10,
        backgroundColor: 'RGBA(204,204,204,0.6)',
      },
    },
  },
  root: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  bgImage: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
  },
  paper: {
    display: 'flex',
    width: 670,
    padding: '60px 0px 60px 0px',
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  logo: {
    marginBottom: 30,
  },
  expiredText: {
    fontSize: 18,
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.appBar + 1,
  },
}))

export default useStyles
