/* eslint-disable no-unused-expressions */
/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup'

const validateImageSize = (value) => {
  value && value[0].size > 1024 * 16
}

const validateImageType = (value) => {
  const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png']
  value && value[0].type.includes(acceptedImageTypes)
}

const validateDomain = (value) => /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,})$/.test(value)
// eslint-disable-next-line no-unused-vars
const validateCharacter = (value) => /^[a-zA-Z0-9 ]+$/.test(value)
const validatePhoneNumber = (value) => /^[0-9]+$/.test(value)
const validateArray = (value) => value.length !== 0
const validateObjectValue = (value) => value !== null
// prettier-ignore
const validatePayment = (value) => [
  'BRI',
  'BNI',
  'MANDIRI',
  'PERMATA',
  'ALFAMART',
  'INDOMARET',
  'OVO',
  'DANA',
  'LINKAJA',
  'BCA',
  'BANK_TRANSFER',
  undefined,
].includes(value)

export const rules = {
  mixed: yup.mixed().test('require', 'tidak boleh kosong', validateObjectValue),
  stringRequired: yup.string('berupa string').required('tidak boleh kosong'),
  phoneNumber: yup
    .string()
    .max(15, 'maksimal 15 digit')
    .required('tidak boleh kosong')
    .test('pattern', 'hanya berupa angka', validatePhoneNumber),
  email: yup
    .string('berupa string')
    .required('tidak boleh kosong')
    .email('tidak valid'),
  password: yup
    .string()
    .required('tidak boleh kosong')
    .min(8, 'minimal 8 karakter dengan setidaknya terdapat 1 angka'),
  imageUploader: yup
    .mixed()
    .test('fileSize', 'maksimal 2mb', validateImageSize)
    .test('fileType', 'file hanya berupa jpeg, png, gif', validateImageType),
  domain: yup
    .string()
    .required('tidak boleh kosong')
    .test('pattern', "simbol yang di izinkan hanya '-'", validateDomain),
  number: yup.number().typeError('Hanya berupa angka').required('tidak boleh kosong'),
  autocomplete: yup.string().required('tidak boleh kosong'),
  multipleCheckboxes: yup
    .mixed()
    .required('tidak boleh kosong')
    .test('null', 'tidak boleh kosong', validateArray),
  paymentMethod: yup
    .string()
    .test('required', 'pilih metode pembayaran', validatePayment),
  paymentMethod2: yup.string().when('price', {
    is: (value) => value !== '0',
    then: yup.string().required('tidak boleh kosong'),
  }),
}

export const schema = (object) => yup.object().shape(object)
