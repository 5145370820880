import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  txResetPassword: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 10,
  },
  txDescription: {
    marginBottom: 27,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      width: '90%',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
}))

export default useStyles
