/* eslint-disable max-len */
import {
  Box, Button, CircularProgress, IconButton, Typography,
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { Helmet } from 'react-helmet'
import { yupResolver } from '@hookform/resolvers/yup'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FieldTextEmail from '@components/CustomFields/FieldTextEmail'
import FieldTextPassword from '@components/CustomFields/FieldTextPassword'
import images from '@assets/images'
import icons from '@assets/icons'
import application from '@config/application'
import { saasApi } from '@api/saasApi'
import { schema, rules } from '@config/yup.schema'
import GoogleLogin from 'react-google-login'
import env from '@config/env'
import { getToken } from '@base/firebase'
import ReactPixel from 'react-facebook-pixel'
import useStyles from './login-jss'

const Login = () => {
  const classes = useStyles()
  const {
    handleSubmit, control, errors, register,
  } = useForm({
    resolver: yupResolver(schema({
      username: rules.email,
      password: rules.password,
    })),
  })
  const { enqueueSnackbar } = useSnackbar()
  const [isSubmitting, setSubmitting] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [fbaseToken, setFbaseToken] = useState()
  const history = useHistory()

  useEffect(() => {
    getToken(setFbaseToken)
    ReactPixel.init(env.fbPixelsId)
    ReactPixel.track('visit page')
  }, [])

  // eslint-disable-next-line no-unused-vars
  const onSubmit = async (value) => {
    const params = {
      ...value,
      scope: 'saas_user',
      grant_type: 'password',
      token_fcm: fbaseToken,
    }

    try {
      setSubmitting(true)
      const { status, data } = await saasApi.post('/oauth/token', params)

      if (status === 200) {
        setSubmitting(false)
        // eslint-disable-next-line camelcase

        await localStorage.setItem('token', data?.access_token)
        await localStorage.setItem('scope', data?.scope)
        await localStorage.setItem('payload', JSON.stringify(data?.data))

        enqueueSnackbar('Login Berhasil', { variant: 'success' })
        window.location.href = `${application.path}/dashboard`
      }
    } catch (error) {
      setSubmitting(false)
      enqueueSnackbar(
        error.response?.data?.error,
        { variant: 'error' },
      )
    }
  }

  const googlResponse = async (response) => {
    setSubmitting(true)
    // eslint-disable-next-line no-console
    const params = {
      accessToken: response.accessToken,
      provider: 'google',
    }

    try {
      const res = await saasApi.get('/api/v1/saas_users/verify_token', { params })
      if (res.status === 200) {
        setSubmitting(false)
        const { data } = res
        await localStorage.setItem('token', data?.access_token)
        await localStorage.setItem('scope', data?.scope)
        await localStorage.setItem('payload', JSON.stringify(data?.data))

        enqueueSnackbar('Login Berhasil', { variant: 'success' })
        history.push(`${application.path}/dashboard`)
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.error || 'Something went wrong', { variant: 'error' })
      setSubmitting(false)
    }
  }
  const responseFacebook = async (response) => {
    // eslint-disable-next-line no-console
    setSubmitting(true)
    // eslint-disable-next-line no-console
    const params = {
      accessToken: response.accessToken,
      id: response.id,
      provider: 'facebook',
    }

    try {
      const res = await saasApi.get('/api/v1/saas_users/verify_token', { params })
      if (res.status === 200) {
        setSubmitting(false)
        const { data } = res
        await localStorage.setItem('token', data?.access_token)
        await localStorage.setItem('scope', data?.scope)
        await localStorage.setItem('payload', JSON.stringify(data?.data))

        enqueueSnackbar('Login Berhasil', { variant: 'success' })
        history.push(`${application.path}/dashboard`)
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.error || 'Something went wrong', { variant: 'error' })
      setSubmitting(false)
    }
  }

  return (
    <Box className={classes.root}>
      <Helmet>
        <title>Squid | SaaS System - Login</title>
      </Helmet>
      <Box className={classes.background}>
        <img src={images.BgIllustration} alt="background" className={classes.shape} />
      </Box>

      {/* FORM CONTAINER */}
      <Box className={classes.formContainer}>
        <img src={icons.AppLogo} alt="logo" className={classes.logo} width={128} height={100} />
        <Typography variant="h6" className={classes.title}>
          Masuk Ke Dashboard
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FieldTextEmail
            errors={errors}
            control={control}
            defaultValue=""
            name="username"
            placeholder="Email"
            register={register}
            isSubmitting={isSubmitting}
          />
          <FieldTextPassword
            name="password"
            control={control}
            defaultValue=""
            errors={errors}
            isSubmitting={isSubmitting}
            register={register}
            placeholder="Password"
          />
          <Typography
            variant="caption"
            color="primary"
            align="right"
            className={classes.forgotPassword}
            component={Link}
            to="/forgot-password"
          >
            Lupa Kata Sandi ?
          </Typography>

          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
          >
            { isSubmitting ? <CircularProgress size={24} color="secondary" /> : 'Masuk'}
          </Button>
        </form>

        <Typography
          variant="caption"
          style={{ color: '#676767' }}
        >
          Atau Masuk Dengan
        </Typography>

        <Box display="flex">
          <GoogleLogin
            clientId={env.googleClientId}
            onSuccess={() => googlResponse}
            onFailure={() => googlResponse}
            cookiePolicy="single_host_origin"
            render={(renderProps) => (
              <IconButton size="small" onClick={renderProps.onClick}>
                <img
                  src={images.GoogleLogo}
                  alt="google"
                  className={classes.socialIcon}
                />
              </IconButton>
            )}
          />
          <FacebookLogin
            appId={env.facebookAppId}
            callback={() => responseFacebook}
            fields="name, email, picture"
            scope="public_profile"
            render={(renderProps) => (
              <IconButton size="small" onClick={renderProps.onClick}>
                <img
                  src={images.FacebookLogo}
                  alt="facebook"
                  className={classes.socialIcon}
                />
              </IconButton>
            )}
          />
        </Box>

        <Typography
          variant="caption"
          className={classes.txNoAccount}
          component={Link}
          to="/register"
        >
          Belum Punya Akun?
          <span className={classes.txRegister}> Daftar </span>
        </Typography>
        <div className={classes.version}>v1.0.0-rc1</div>

      </Box>
    </Box>
  )
}

export default Login
