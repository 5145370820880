/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */
import React, {
  useState, useContext, useEffect, Fragment,
} from 'react'
import PropTypes from 'prop-types'
import {
  AppBar,
  Avatar,
  Badge,
  Button,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Fade,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import clsx from 'clsx'
import MenuIcon from '@material-ui/icons/Menu'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import SearchIcon from '@material-ui/icons/Search'
import { useHistory } from 'react-router-dom'
import icons from '@assets/icons'
import application from '@config/application'
import { onMessageListener } from '@base/firebase'
import { useSnackbar } from 'notistack'
import CloseIcon from '@material-ui/icons/Close'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { Autocomplete } from '@material-ui/lab'
import { saasApiWithToken } from '@api/saasApi'
import useStyles from './header-jss'
import { getCollectionApi, updateApi } from './api'
import { AdminContext } from '../../containers/Layout/AdminProvider'

const Header = ({ open, toggleDrawer, pageTitle }) => {
  const classes = useStyles()
  const history = useHistory()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [invisible, setInvisible] = useState(true)
  const name = localStorage.getItem('payload') ? JSON.parse(localStorage.getItem('payload')).name : 'User'
  const { state, dispatch } = useContext(AdminContext)

  const getNotif = async () => {
    const res = await getCollectionApi()
    const { status, data: { data } } = res
    if (status === 200) {
      dispatch({
        type: 'refreshNotif',
        value: data || [],
      })
      const isUnread = data.filter((val) => val.has_read === false) || []
      if (isUnread.length > 0) setInvisible(false)
    }
  }

  useEffect(() => {
    getNotif()
  }, [])

  const action = (key) => (
    <Fragment>
      <IconButton size="small" onClick={() => { closeSnackbar(key) }}>
        <CloseIcon fontSize="inherit" style={{ color: '#FFFFFF' }} />
      </IconButton>
    </Fragment>
  )

  const triggerNewNotif = (payload) => {
    getNotif()
    const { notification: { body } } = payload
    const snackProp = {
      variant: 'info',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      TransitionComponent: Fade,
      action,
    }
    enqueueSnackbar(body, snackProp)
  }

  onMessageListener()
    .then((payload) => triggerNewNotif(payload))
    .catch((err) => err)

  const getInitialName = () => {
    const arrName = name.split(' ')
    let initialName = ''

    // eslint-disable-next-line no-return-assign
    arrName.map((str) => initialName += str.charAt(0))

    return initialName.toUpperCase()
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget)
    setInvisible(true)
  }

  const handleClose2 = () => {
    setMenuOpen(false)
    setAnchorEl2(null)
  }

  const handleClose = () => {
    setMenuOpen(false)
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    handleClose()
    await localStorage.clear()
    history.push('/login')
  }

  const handleNotif = async (id, act, read) => {
    if (!read) {
      const res = await updateApi(id)
      const { status } = res
      if (status === 200) getNotif()
    }
    handleClose2()
    history.push(act)
  }

  const renderMenu = () => (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={menuOpen}
      onClose={handleClose}
      elevation={0}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      classes={{
        paper: clsx(classes.notification, classes.userMenu),
      }}
    >
      <MenuItem onClick={() => {
        handleClose()
        history.push(`${application.path}/account`)
      }}
      >
        <ListItemIcon>
          <img
            src={icons.UserIcon}
            alt="user"
            height={18}
            width={18}
          />
        </ListItemIcon>
        <ListItemText primary="Akun Saya" style={{ marginLeft: -16 }} />
      </MenuItem>
      <MenuItem onClick={() => {
        handleClose()
        history.push(`${application.path}/change-password`)
      }}
      >
        <ListItemIcon>
          <img
            src={icons.KeyIcon}
            alt="user"
            height={18}
            width={18}
          />
        </ListItemIcon>
        <ListItemText primary="Ubah Kata Sandi" style={{ marginLeft: -16 }} />
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <img
            src={icons.LogoutIcon}
            alt="user"
            height={18}
            width={18}
          />
        </ListItemIcon>
        <ListItemText primary="Keluar" style={{ marginLeft: -16 }} />
      </MenuItem>
    </Menu>
  )

  const renderNotificationMenu = () => (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl2}
      keepMounted
      open={Boolean(anchorEl2)}
      onClose={handleClose2}
      elevation={0}
      classes={{
        paper: classes.notification,
      }}
    >
      <List>
        {
          (state.notifList.length > 0) && state.notifList.map((item) => (
            <div key={item.id}>
              <ListItem
                button
                onClick={() => handleNotif(item.id, item.action, item.has_read)}
                className={(item.has_read) ? classes.listItem : classes.listItemUnread}
              >
                <ListItemIcon>
                  {(item.has_read) ? (<NotificationsIcon />) : (<NotificationsActiveIcon />)}
                </ListItemIcon>
                <ListItemText
                  primary={item.title}
                  secondary={item.message}
                  variant="caption"
                  className={classes.secondaryText}
                />
                <Typography className={classes.notificationTime}>
                  {item.time}
                </Typography>
              </ListItem>
              <Divider />
            </div>
          ))
        }
        {
          (state.notifList.length > 0) && (
            <Button fullWidth onClick={() => history.push(`${application.path}/notification`)}>
              Lihat Semua
            </Button>
          )
        }
        {
          (state.notifList.length === 0) && (
            <Button disabled fullWidth>
              Tidak ada notifikasi
            </Button>
          )
        }
      </List>
    </Menu>
  )

  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const searchStore = async (e) => {
    try {
      setLoading(true)
      const { status, data: { data } } = await saasApiWithToken.get('/saas_systems/stores/search', { params: { name: e.target.value } })
      if (status === 200) {
        setLoading(false)
        setOptions(data)
      } else {
        setOptions([])
      }
    } catch (error) {
      setLoading(false)
      setOptions([])
    }
  }

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, classes.appBarRoot, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar
        className={classes.toolbar}
      >
        <IconButton
          className={clsx({ [classes.hide]: open })}
          olor="primary"
          onClick={toggleDrawer}
        >
          <MenuIcon className={classes.menuIcon} />
        </IconButton>
        <Typography
          variant="h1"
          color="primary"
          className={classes.menuTitle}
        >
          {pageTitle}
        </Typography>

        <div className={classes.toolbarAction}>
          <Autocomplete
            name="searchStore"
            options={options}
            loading={loading}
            getOptionSelected={(option, value) => option.label === value.label}
            getOptionLabel={(option) => option.label}
            disableClearable
            onChange={(e, value, reason) => {
              if (reason === 'select-option') {
                history.push(`/app/list-store?name=${value.label}`)
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="search"
                onChange={searchStore}
                className={classes.inputSearch}
                placeholder="Cari..."
                InputProps={{
                  disableUnderline: true,
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="primary" />
                    </InputAdornment>),
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}

              />
            )}
          />
          <IconButton
            className={classes.iconButton}
            color="primary"
            onClick={handleClick2}
          >
            <Badge variant="dot" invisible={invisible} color="secondary">
              <NotificationsNoneIcon />
            </Badge>
          </IconButton>
          <Chip
            avatar={<Avatar>{getInitialName()}</Avatar>}
            label={name}
            className={classes.chip}
            deleteIcon={(
              <div>
                {menuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </div>
            )}
            onDelete={handleClick}
            onClick={handleClick}
            clickable
          />
        </div>
      </Toolbar>
      {renderMenu()}
      {renderNotificationMenu()}
    </AppBar>
  )
}
Header.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  pageTitle: PropTypes.string.isRequired,
}

export default Header
