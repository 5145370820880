import BgIllustration from './images/bg-illustration.png'
import FacebookLogo from './images/logo/facebook.png'
import GoogleLogo from './images/logo/google.png'
import BgTree from './images/tree.png'
import BgRegister from './images/bg-register.png'
import BgTreeSmall from './images/bg-tree.png'
import BlockImg from './images/block.png'
import FloatingButton from './images/floating-button.png'
import BCA from './images/logo/BCA.png'
import BNI from './images/logo/BNI.svg'
import BRI from './images/logo/BRI.png'
import MANDIRI from './images/logo/MANDIRI.png'
import PERMATA from './images/logo/PERMATA.png'
import OVO from './images/logo/OVO.png'
import DANA from './images/logo/DANA.png'
import Avatar from './images/avatar.svg'
import Protection from './images/logo/protection.svg'

const images = {
  Protection,
  OVO,
  DANA,
  BCA,
  BNI,
  BRI,
  MANDIRI,
  PERMATA,
  Avatar,
  FloatingButton,
  BgIllustration,
  FacebookLogo,
  GoogleLogo,
  BgTree,
  BgRegister,
  BgTreeSmall,
  BlockImg,
}

export default images
