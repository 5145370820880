/* eslint-disable react/prop-types */
import { SnackbarProvider } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import application from '../config/application'
import ForgotPassword from '../containers/Pages/Auth/ForgotPassword'
import ForgotResult from '../containers/Pages/Auth/ForgotPassword/ForgotResult'
import Login from '../containers/Pages/Auth/Login'
import Register from '../containers/Pages/Auth/Register'
import RegisterPayment from '../containers/Pages/Auth/Register/RegisterPayment'
import RegisterSuccess from '../containers/Pages/Auth/Register/RegisterSuccess'
import ResetPassword from '../containers/Pages/Auth/ResetPassword'
import NotFound from '../containers/Pages/NotFound'
import useWidth from '../hooks/useWidth'
import AdminRoutes from './AdminRoutes'
import ManualPayment from '../containers/Pages/Auth/Register/ManualPayment'
import EmailConfirmation from '../containers/Pages/Auth/Register/EmailConfirmation'

const Routes = ({ history }) => {
  const width = useWidth()
  const [snackbarProps, setSnackbarProps] = useState({
    maxSnack: 3,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  })

  useEffect(() => {
    if (width === 'sm' || width === 'xs') {
      setSnackbarProps({
        ...snackbarProps,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      })
    } else {
      setSnackbarProps({
        ...snackbarProps,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width])

  return (
    <SnackbarProvider {...snackbarProps}>
      <Switch history={history}>
        <Route exact path="/" component={Login} />
        <Route path="/login" component={Login} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route path="/forgot-password/result" component={ForgotResult} />
        <Route path="/reset_password" component={ResetPassword} />
        <Route path="/register" component={Register} />
        <Route path="/register-success" component={RegisterSuccess} />
        <Route path="/register-payment" component={RegisterPayment} />
        <Route path="/manual-payment" component={ManualPayment} />
        <Route path="/email-confirmation/:status" component={EmailConfirmation} />

        <Route path={application.path} render={(props) => <AdminRoutes {...props} />} />

        <Route component={NotFound} />
      </Switch>
    </SnackbarProvider>
  )
}
export default Routes
