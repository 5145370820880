import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
  Collapse,
  Divider,
  Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem,
} from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { useHistory, useLocation } from 'react-router-dom'
import icons from '@assets/icons'
import { menu } from '@routes/menu'
import application from '@config/application'
import useWidth from '@hooks/useWidth'
import useStyles from './sidebar-jss'

const Sidebar = ({ open, toggleDrawer }) => {
  const classes = useStyles()
  const location = useLocation()
  const [selectedIndex, setSelectedIndex] = useState(location.pathname.replace(`${application.path}/`, ''))
  const [selectedIndexChild, setSelectedIndexChild] = useState(location.pathname.replace(`${application.path}/`, ''))
  const [collapse, setCollapse] = useState(false)
  const history = useHistory()
  const width = useWidth()

  const handleListItemClick = (event, index, link) => {
    setSelectedIndex(index)
    if (link) {
      history.push(`${application.path}${link}`)
      setCollapse(false)
    }
    if (!link) setCollapse(!collapse)
    if (['sm', 'xs'].includes(width) && link) toggleDrawer()
  }

  const handleChildListItemClick = (event, index, link) => {
    setSelectedIndexChild(index)
    if (['sm', 'xs'].includes(width)) toggleDrawer()
    history.push(`${application.path}${link}`)
  }

  const handleMyAccount = () => {
    history.push(`${application.path}/account`)
    toggleDrawer()
  }

  const handleChangePassword = () => {
    history.push(`${application.path}/change-password`)
    toggleDrawer()
  }

  const handleLogout = () => {
    history.push('/login')
  }

  return (
    <div className={classes.root}>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton
            onClick={toggleDrawer}
            className={classes.drawerButton}
          >
            {
            open
              ? <ArrowBackIosIcon className={classes.drawerButtonIcon} />
              : <ArrowForwardIosIcon className={classes.drawerButtonIcon} />
}
          </IconButton>
          <img src={icons.AppLogoWhite} alt="logo" />
        </div>
        <div className={classes.drawerContent}>
          <List className={classes.list}>
            {
              menu.map((item) => (
                <div key={item.id}>
                  <ListItem
                    key={item.id}
                    button
                    className={classes.listItem}
                    selected={selectedIndex === item.id}
                    onClick={(event) => handleListItemClick(event, item.id, item.link)}
                  >
                    <ListItemIcon>
                      {selectedIndex === item.id ? item.iconSelected : item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.title} />
                    {
                    item.child
                    && (collapse ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)
                  }
                  </ListItem>

                  {
                    item.child
                    && (
                    <Collapse in={collapse && (selectedIndex === item.id)} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {
                          item.child.map((child) => (
                            <ListItem
                              key={child.id}
                              button
                              onClick={(e) => handleChildListItemClick(e, child.id, child.link)}
                              selected={selectedIndexChild === child.id}
                              className={classes.nested}
                            >
                              <ListItemIcon>
                                {child.icon}
                              </ListItemIcon>
                              <ListItemText primary={child.title} />
                            </ListItem>
                          ))
                        }
                      </List>
                    </Collapse>
                    )
                  }
                </div>
              ))
            }
          </List>
        </div>
        <div className={classes.mobileMenuUser}>
          <Divider className={classes.userMenuDivider} />
          <MenuItem onClick={handleMyAccount}>
            <ListItemIcon>
              <img
                src={icons.UserIcon}
                alt="user"
                height={18}
                width={18}
              />
            </ListItemIcon>
            <ListItemText primary="Akun Saya" style={{ marginLeft: -16 }} />
          </MenuItem>
          <MenuItem onClick={handleChangePassword}>
            <ListItemIcon>
              <img
                src={icons.KeyIcon}
                alt="user"
                height={18}
                width={18}
              />
            </ListItemIcon>
            <ListItemText primary="Ubah Kata Sandi" style={{ marginLeft: -16 }} />
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <img
                src={icons.LogoutIcon}
                alt="user"
                height={18}
                width={18}
              />
            </ListItemIcon>
            <ListItemText primary="Keluar" style={{ marginLeft: -16 }} />
          </MenuItem>
        </div>
      </Drawer>
    </div>
  )
}

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
}

export default Sidebar
