import React, { useState, useEffect } from 'react'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import AuthManagement from '@layout/AuthManagement'
import FieldTextPassword from '@components/CustomFields/FieldTextPassword'
import useQuery from '@hooks/useQuery'
import { saasApi } from '@api/saasApi'
import { rules, schema } from '@config/yup.schema'
import useStyles from './resetPassword-jss'

const ResetPassword = () => {
  const classes = useStyles()
  const {
    handleSubmit, control, errors, register,
  } = useForm({
    resolver: yupResolver(schema({
      password: rules.password,
      password_confirmation: rules.password,
    })),
  })
  const { enqueueSnackbar } = useSnackbar()
  const [isSubmitting, setSubmitting] = useState(false)
  const query = useQuery()
  const history = useHistory()

  const onSubmit = async (value) => {
    const params = {
      reset_password_token: query.get('reset_password_token'),
      saas_user: value,
    }
    try {
      setSubmitting(true)
      // eslint-disable-next-line no-shadow
      const { data: { success, errors } } = await saasApi.put('/api/v1/saas_users/password', params)

      if (success) {
        setSubmitting(false)
        enqueueSnackbar('Password berhasil di ubah',
          { variant: 'success' })
        history.push('/')
      } else {
        setSubmitting(false)
        Object.keys(errors).map((key) => enqueueSnackbar(errors[key], { variant: 'error' }))
      }
    } catch (error) {
      setSubmitting(false)
      enqueueSnackbar('Maaf, terjadi kesalahan pada sistem',
        { variant: 'error' })
    }
  }

  useEffect(() => {
    if (query.get('reset_password_token') === null) history.goBack()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.get('reset_password_token')])

  return (
    <AuthManagement pageTitle="Squids | Reset Password">
      <Typography variant="h6" className={classes.txForgotPassword}>
        Buat Kata Sandi Baru
      </Typography>
      <Typography variant="body2" className={classes.txDescription} style={{ marginBottom: 6 }}>
        Buat kata sandi baru minimal 8 karakter yang terdiri dari kombinasi
      </Typography>
      <Typography variant="body2" className={classes.txDescription}>
        huruf besar, huruf kecil, angka, dan simbol.
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className={classes.form}>
        <FieldTextPassword
          name="password"
          control={control}
          defaultValue=""
          errors={errors}
          isSubmitting={isSubmitting}
          register={register}
          placeholder="Password"
        />
        <FieldTextPassword
          name="password_confirmation"
          control={control}
          defaultValue=""
          errors={errors}
          isSubmitting={isSubmitting}
          register={register}
          placeholder="Konfirmasi Password"
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
        >
          {
            isSubmitting
              ? <CircularProgress color="secondary" size={24} />
              : 'Kirim'
          }
        </Button>
      </form>
    </AuthManagement>
  )
}

ResetPassword.propTypes = {

}

export default ResetPassword
