/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Card,
  Divider,
  FormHelperText,
  Grid,
  Typography,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useSnackbar } from 'notistack'
import icons from '@assets/icons'
import { saasApi } from '@api/saasApi'
import LoadingApi from '@components/LoadingApi'
import useQuery from '@hooks/useQuery'
import FieldTextVoucher from '@components/CustomFields/FieldTextVoucher'
import RupiasFormat from '@components/RupiasFormat'
import FieldText from '@components/CustomFields/FieldText'
import FieldAutocomplete2 from '@components/CustomFields/FieldAutocomplete2'
import useStyles from './styles-jss/packageInformation-jss'

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const PackageInformation = ({
  register,
  setValue,
  errors,
  watch,
  ...props
}) => {
  const query = useQuery()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [selectedPackage, setSelectedPackage] = useState()
  const [packageData, setPackageData] = useState([])
  const [loading, setLoading] = useState(false)
  const active = watch('type_package_period')
  const [packageNameWeb, setPackageNameWeb] = useState(localStorage.getItem('packageNameWeb'))
    || query.get('packageNameWeb')
  const [packagePeriodWeb, setPackagePeriodWeb] = useState(localStorage.getItem('packagePeriodWeb') ?? 'monthly')
    || query.get('packagePeriodWeb')
  const [voucherCode, setVoucherCode] = useState()
  const [voucher, setVoucher] = useState(null)
  const [value, setValues] = React.useState(0)

  const onPackageChange = (index, period) => {
    if (packageData?.length) {
      setVoucher(null)
      setSelectedPackage(packageData[index])
      setValue('package_name', packageData[index]?.id, {
        shouldValidate: true,
      })
      setValue(
        'package_custom_domain',
        packageData[index]?.package_details[0]?.custom_domain,
      )
      setValue(
        'price',
        packageData[index][`${period}_price`] === '0.0'
          ? 0
          : packageData[index][`${period}_price`],
        { shouldValidate: true },
      )
      if (packageData[index][`${period}_price`] === '0.0') {
        setValue('payment_method', 'free', { shouldValidate: true })
      }
    }
  }

  const getPackages = async () => {
    try {
      setLoading(true)
      const {
        status,
        data: { data },
      } = await saasApi.get('api/v1/saas_systems/packages')
      if (status === 200) {
        setPackageData(data)
        setLoading(false)
      }
    } catch (error) {
      enqueueSnackbar('Maaf, terjadi kesalahan pada sistem', {
        variant: 'error',
      })
    }
  }
  const getListBanks = () => saasApi.get('api/v1/saas_systems/banks?per_page=1000')
  const setPackagePeriod = (period) => {
    setValue('type_package_period', period, { shouldValidate: true })
  }

  useEffect(() => {
    setPackagePeriod(packagePeriodWeb)
    getPackages()
    getListBanks()
  }, [packageNameWeb, packagePeriodWeb])

  useEffect(() => {
    if (query.get('packageNameWeb')) {
      localStorage.setItem('packageNameWeb', query.get('packageNameWeb'))
      setPackageNameWeb(query.get('packageNameWeb'))
    }
    if (query.get('packagePeriodWeb')) {
      localStorage.setItem('packagePeriodWeb', query.get('packagePeriodWeb'))
      setPackagePeriodWeb(query.get('packagePeriodWeb'))
    }
    if (
      query.get('ref')
      || query.get('packageNameWeb')
      || query.get('packagePeriodWeb')
    ) {
      window.history.replaceState(null, '', window.location.pathname)
    }
  }, [query])

  useEffect(() => {
    if (
      packageNameWeb
      && packagePeriodWeb
      && packageData
      && packageData?.length > 0
    ) {
      const findPackage = packageData?.find(({ name }) => name?.toLowerCase()?.includes(packageNameWeb))
      const indexed = packageData?.findIndex(({ name }) => name?.toLowerCase()?.includes(packageNameWeb))
      if (findPackage) {
        onPackageChange(indexed, packagePeriodWeb, true)
        setValues(indexed)
      }
    } else {
      onPackageChange(0, packagePeriodWeb, true)
    }
  }, [packageData])

  const checkVoucher = async (code, packageID, period) => {
    const params = {
      code,
      package_id: packageID,
      period,
    }
    try {
      setLoading(true)
      const {
        status,
        data: { data },
      } = await saasApi.get('api/v1/saas_systems/voucher_packages/check_code', {
        params,
      })
      if (status === 200 && data && data?.id) {
        setLoading(false)
        setVoucher(data)
        setValue('voucher_package', data)
      } else {
        setLoading(false)
        setVoucher(data)
        setValue('voucher_package', null)
      }
    } catch (error) {
      setLoading(false)
      setVoucher(null)
      setValue('voucher_package', null)
      enqueueSnackbar('Maaf, terjadi kesalahan pada sistem', {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (voucherCode && selectedPackage) {
        checkVoucher(voucherCode, selectedPackage?.id, active)
      }
    }, 2000)

    return () => clearTimeout(delayDebounceFn)
  }, [voucherCode, selectedPackage])

  useEffect(() => {
    if (selectedPackage?.package_details?.[0]?.reseller_total > 0) {
      if (
        parseInt(watch('total_reseller'))
        > selectedPackage?.package_details?.[0]?.reseller_total
      ) {
        setValue(
          'total_reseller',
          selectedPackage?.package_details?.[0]?.reseller_total,
        )
      }
    }
  }, [selectedPackage, watch('total_reseller')])

  const TabPanel = (prop) => {
    const {
      children, values, index, ...other
    } = prop

    return (
      <div
        role="tabpanel"
        hidden={values !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {values === index && children}
      </div>
    )
  }

  const handleChange = (event, newValue) => {
    setValues(newValue)
    onPackageChange(newValue, packagePeriodWeb, false)
  }
  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.txTitle}>
        Pilih Paket Anda
      </Typography>
      <Box>
        <Divider className={classes.dividerText} />
      </Box>
      <Grid justify="center" container style={{ marginTop: 22 }}>
        <Button
          onClick={() => setPackagePeriod('monthly')}
          className={
            active === 'monthly' ? classes.btnPeriodActive : classes.btnPeriod
          }
        >
          Bulanan
        </Button>
        <Button
          onClick={() => setPackagePeriod('annual')}
          className={
            active !== 'monthly' ? classes.btnPeriodActive : classes.btnPeriod
          }
        >
          Tahunan
        </Button>
      </Grid>
      <Box
        style={{
          flexGrow: 1,
          width: '100%',
        }}
      >
        <AppBar
          position="static"
          color="default"
          className={classes.tabsPackage}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant={packageData?.length > 3 ? 'scrollable' : 'fullwidth'}
            scrollButtons="auto"
            aria-label="full width tabs example"
          >
            {packageData
              ?.sort(
                (b, a) => (b.package_details[0].position || 0)
                  - (a.package_details[0].position || 0),
              )
              .map((d, i) => (
                <Tab
                  className={classes.tabStyle}
                  label={d?.name}
                  {...a11yProps(i)}
                />
              ))}
          </Tabs>
        </AppBar>
        {loading && <LoadingApi loading={loading} />}
        {!loading
          && packageData
            .sort(
              (b, a) => (b.package_details[0].position || 0)
                - (a.package_details[0].position || 0),
            )
            .map((item, index) => (
              <TabPanel values={value} index={index} style={{ paddingTop: 24 }}>
                <Card className={classes.card}>
                  <div className={classes.cardContent}>
                    <Typography className={classes.txPackageName}>
                      {item?.name}
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'end' }}>
                      <Typography className={classes.txPackagePrice}>
                        {item.omzet_based_billing
                          ? `${item.omzet_percentage} % dari omzet`
                          : item[`${active}_price`] !== '0.0'
                            ? `${item[`formatted_${active}_price`]}`
                            : 'Hubungi Admin'}
                      </Typography>
                      <Typography style={{ color: '#fff', fontSize: 14 }}>
                        {item[`${active}_price`] !== '0.0'
                          ? active === 'annual'
                            ? ' /Tahun'
                            : ' /Bulan'
                          : ''}
                      </Typography>
                    </div>
                  </div>
                  <img
                    src={icons.NewCheck}
                    alt="checklist"
                    height={24}
                    width={32}
                  />
                </Card>
                <Typography className={classes.txDesc}>
                  {item?.description}
                </Typography>
                <Typography className={classes.txTitle}>Rincian</Typography>
                {item?.package_details[0]?.descriptions.map(
                  (items) => items !== 'Unlimited hari masa trial.'
                    && items !== '0 hari masa trial.' && (
                      <Box
                        key={items}
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                      >
                        <img
                          src={
                            items?.includes('Tidak')
                              ? icons.ClosePackages
                              : icons.CheckPackages
                          }
                          alt="checklist"
                          height={10}
                          width={10}
                        />
                        <Box flex={1} marginLeft={1}>
                          <Typography
                            variant="caption"
                            className={classes.txDetails}
                          >
                            {items}
                          </Typography>
                        </Box>
                      </Box>
                  ),
                )}
                <Box display="flex" alignItems="center" flexDirection="row">
                  <img
                    src={
                      !item?.package_details[0]?.custom_domain
                        ? icons.ClosePackages
                        : icons.CheckPackages
                    }
                    alt="checklist"
                    height={10}
                    width={10}
                  />
                  <Box flex={1} marginLeft={1}>
                    <Typography variant="caption" className={classes.txDetails}>
                      {item.package_details[0]?.custom_domain
                        ? 'Sudah Termasuk Custom Domain'
                        : 'Tidak Termasuk Custom Domain'}
                    </Typography>
                  </Box>
                </Box>
              </TabPanel>
            ))}
      </Box>
      {!loading
        && selectedPackage
        && selectedPackage[`${active}_price`] !== '0.0' && (
          <div style={{ margin: '16px 0px 16px 0px' }}>
            <Box display="flex" justifyContent="space-between">
              <Typography className={classes.txTotalPrice}>
                Total {!voucher && 'Bayar'}
              </Typography>
              <Typography
                color="secondary"
                className={
                  voucher?.id ? classes.txPriceDefault : classes.txPrice
                }
              >
                {selectedPackage[`${active}_price`] === '0.0'
                  ? 'Free'
                  : selectedPackage[`formatted_${active}_price`]}
              </Typography>
            </Box>
          </div>
      )}
      {!loading
        && selectedPackage
        && selectedPackage[`${active}_price`] === '0.0' && (
          <div style={{ margin: '16px 0px 16px 0px' }}>
            <Box display="flex" justifyContent="space-between">
              <Typography className={classes.txTotalPrice}>
                Hubungi Admin
              </Typography>
              <Button
                onClick={() => {
                  window.open(
                    'https://api.whatsapp.com/send?phone=6281321832432&text=Halo%20tim%20Squids,%20saya%20tertarik%20dengan%20paket%20Paket%20Kraken',
                    '_blank',
                  )
                }}
                className={classes.btnWa}
              >
                <img
                  src={icons.Whatsapp}
                  alt="Whatsapp"
                  height={22}
                  width={22}
                />
                <Typography className={classes.textWa}>Admin</Typography>
              </Button>
            </Box>
          </div>
      )}
      {selectedPackage && selectedPackage[`${active}_price`] !== '0.0' && (
        <FieldTextVoucher
          name="voucher"
          label="Kode Voucher"
          placeholder="Masukan Kode Voucher Anda"
          setVoucherCode={setVoucherCode}
          styleBox={{ marginTop: 0, marginBottom: 8 }}
          // register={register}
          // isSubmitting={isSubmitting}
          // {...props}
        />
      )}
      {!loading
        && selectedPackage
        && selectedPackage[`${active}_price`] !== '0.0'
        && voucherCode && (
          <Alert severity={voucher?.id ? 'success' : 'error'}>
            {voucher?.id ? voucher?.name : voucher?.message}
          </Alert>
      )}
      {!loading && selectedPackage && voucher?.id && (
        <div style={{ margin: '16px 0px 16px 0px' }}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.txTotalPrice}>
              Total Potongan Voucher
            </Typography>
            <Typography className={classes.txPrice}>
              - {voucher?.formatted_voucher_amount}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.txTotalPrice}>
              Total Biaya Registrasi
            </Typography>
            <Typography className={classes.txPrice}>
              <RupiasFormat
                displayType="text"
                value={
                  selectedPackage[`${active}_price`] - voucher?.voucher_amount
                }
              />
            </Typography>
          </Box>
        </div>
      )}
      <input hidden name="price" ref={register} />
      {register('package_custom_domain')}
      {register('voucher_package')}
      <input
        hidden
        name="package_name"
        ref={register({ required: 'Anda belum memilih paket' })}
      />
      {errors?.package_name ? (
        <FormHelperText style={{ color: 'red' }}>
          {errors?.package_name?.message}
        </FormHelperText>
      ) : (
        ''
      )}
      {selectedPackage?.omzet_based_billing && (
        <Box>
          <FieldAutocomplete2
            name="bank_id"
            label="Pilih Bank"
            api={getListBanks}
            placeholder="Cari Bank..."
            register={register}
            getOptionValue={(option, val) => option.id === val.id}
            getOptionLabel={(val) => val.name}
            setValue={setValue}
            {...props}
          />
          <FieldText
            name="bank_number"
            label="Nomor Rekening"
            register={register}
            {...props}
          />

          <FieldText
            name="bank_name"
            label="Nama Rekening"
            register={register}
            {...props}
          />
        </Box>
      )}
    </Box>
  )
}

PackageInformation.propTypes = {
  register: PropTypes.any.isRequired,
  setValue: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
}

export default PackageInformation
