import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, { useContext } from 'react'
import { Route, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import application from '@config/application'
import { appbarHeight, drawerWidth } from '@themes/constant'
import { AdminContext } from './AdminProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    height: `calc(100% - ${appbarHeight + 100}px)`,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('sm')]: {
      height: `calc(100% - ${appbarHeight}px)`,
      position: 'relative',
    },
  },
  position: {
    position: 'absolute',
  },
  img: {
    [theme.breakpoints.down('sm')]: {
      height: 300,
      width: 200,
    },
  },
  default: {
    height: '100%',
    width: '100%',
  },
  text: {
    color: '#1A253B',
    fontSize: 20,
    fontWeight: 'bold',
    margin: '16px 0px 16px 0px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const PageHandler = ({ children, pageTitle }) => {
  const classes = useStyles()
  const location = useLocation()
  const isAdminLayout = location.pathname.split('/')[1] === application.path.replace('/', '')
  const adminLayout = useContext(AdminContext)

  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
        staticContext.status = 404; // eslint-disable-line
        }
        return (
          <div className={clsx(classes.root, {
            [classes.default]: !isAdminLayout || !adminLayout?.drawerOpen,
            [classes.position]: !isAdminLayout,
          })}
          >
            <Helmet>
              <title>{pageTitle}</title>
            </Helmet>
            { children }
          </div>
        )
      }}
    />
  )
}

PageHandler.defaultProps = {
  pageTitle: '',
}

PageHandler.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
}

export default PageHandler
