/* eslint-disable react/require-default-props */
import React from 'react'
import {
  Box,
  FormHelperText,
  InputAdornment,
  InputBase,
  InputLabel,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Controller } from 'react-hook-form'
import icons from '@assets/icons'
import useStyles from './styles-jss'

const InputText = (props) => {
  const classes = useStyles()
  const {
    errors,
    name,
    label,
    getvalues,
    inputRef,
    placeholder,
    disabled,
    // eslint-disable-next-line no-unused-vars
    setvalue,
  } = props

  const renderInputAdornment = () => (
    !errors[name] && getvalues(name) !== undefined
    && (
    <img
      src={icons.ChecklistCircle}
      alt="checklist"
      height={20}
      width={20}
    />
    )
  )

  const renderErrorMessage = () => (
    !!errors[name] && errors[name].message
  )

  const renderInputLabel = () => (
    label && (
    <InputLabel className={classes.inputLabel}>
      {label}
    </InputLabel>
    )
  )
  return (
    <Box className={classes.root}>
      {renderInputLabel()}
      <InputBase
        name={name}
        inputRef={inputRef}
        placeholder={placeholder}
        disabled={disabled}
        className={clsx(classes.field, {
          [classes.error]: !!errors[name],
        })}
        endAdornment={((
          <InputAdornment position="end">
            {renderInputAdornment()}
          </InputAdornment>
          )
        )}
        {...props}
      />
      <FormHelperText className={classes.errorMessage}>
        {renderErrorMessage()}
      </FormHelperText>
    </Box>
  )
}

InputText.defaultProps = {
  errors: {},
  placeholder: '',
  label: undefined,
  getvalues: null,
}

InputText.propTypes = {
  errors: PropTypes.object,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  getvalues: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  setvalue: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  inputRef: PropTypes.func,
}

const FieldText = ({
  register,
  control,
  name,
  // eslint-disable-next-line react/prop-types
  component,
  getValues,
  setValue,
  defaultValues,
  ...props
}) => {
  const rest = {
    getvalues: getValues,
    setvalue: setValue,
    defaultvalues: defaultValues,
    ...props,
  }

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      render={({
        onChange, onBlur,
      }) => (
        <InputText
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          inputRef={register}
          {...rest}
        />
      )}
    />
  )
}

FieldText.defaultProps = {
  defaultValues: {},
}

FieldText.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.any.isRequired,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  defaultValues: PropTypes.object,
}

export default FieldText
