import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'fixed',
    height: '100%',
    width: '100%',
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      position: 'relative',
    },
  },
  content: {
    display: 'flex',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    marginBottom: 90,
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      backgroundColor: 'RGBA(85,111,181,0.6)',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginBottom: 0,
      height: '100%',
      width: '100%',
    },
  },
  column: {
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
    height: 'fit-content',
    width: '50%',
    borderRight: '1px solid #E5E5E5',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  background: {
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    width: '35%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  bgImage: {
    position: 'absolute',
    width: '100%',
    top: 0,
  },
  divider: {
    position: 'absolute',
    border: '1px solid #E5E5E5',
  },
  dividerTop: {
    top: 70,
    width: '90%',
  },
  logo: {
    position: 'absolute',
    top: 54,
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      width: 136,
      height: 110,
    },
    [theme.breakpoints.only('xl')]: {
      width: 180,
      height: 164,
    },
  },
  sectionSocialLogin: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: 70,
    alignItems: 'center',
    top: 0,
    left: '5%',
  },
  txRegisterWith: {
    fontSize: 14,
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  socialIconWrapper: {
    display: 'flex',
  },
  socialLogo: {
    width: 27,
    height: 27,
  },
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    height: 70,
    width: '63.8%',
    padding: theme.spacing(1),
    '-webkit-box-shadow': '0px -2px 5px 0px rgba(204,204,204,1);',
    '-moz-box-shadow': '0px -2px 5px 0px rgba(204,204,204,1);',
    'box-shadow': '0px -2px 5px 0px rgba(204,204,204,1);',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: '100%',
      height: 56,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
      padding: 'none',
    },
  },
  btnSubmit: {
    backgroundColor: '#24E09B',
    color: 'white',
    width: 380,
    height: 40,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
}))

export default useStyles
