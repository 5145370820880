import React from 'react'
import { CircularProgress } from '@material-ui/core'
import PageHandler from '@layout/PageHandler'

const LoadingPage = () => (
  <PageHandler>
    <CircularProgress color="primary" size={42} />
    <br />
    Tunggu yaah..
  </PageHandler>
)

export default LoadingPage
