import MailIcon from './icons/email.svg'
import LockIcon from './icons/lock.svg'
import VisibilityIcon from './icons/visibility.svg'
import AppLogo from './logo.svg'
import AppLogoWhite from './logo-white.svg'
import ChecklistCircle from './icons/circle-checklist.svg'
import BankTransfer from './icons/bank-transfer.svg'
import BcaVirtualAccount from './icons/bca-virtual-account.svg'
import GopayIcon from './icons/gopay.svg'
import ChecklistIcon from './icons/checklist.svg'
import ChecklistPrimaryIcon from './icons/checklist-primary.svg'
import DashboardIcon from './icons/dashboard.svg'
import DashboardBlack from './icons/dashboard-black.svg'
import HomeIcon from './icons/home.svg'
import HomeBlack from './icons/home-black.svg'
import IntergrationIcon from './icons/integration.svg'
import IntergrationBlack from './icons/integration-black.svg'
import FileIcon from './icons/file.svg'
import FileBlack from './icons/file-black.svg'
import WomanWithLaptopIcon from './icons/woman-with-laptop.svg'
import CircleGroup from './icons/circle-group.svg'
import KeyIcon from './icons/key.svg'
import LogoutIcon from './icons/logout.svg'
import UserIcon from './icons/user.svg'
import ShoppingBagIcon from './icons/shopping-bag.svg'
import TrendingUpIcon from './icons/trending-up.svg'
import BoxIcon from './icons/box.svg'
import ClockIcon from './icons/clock.svg'
import FloatingButtonIcon from './icons/floating-button-icon.svg'
import ShppingBagOutlined from './icons/shopping-bag-outlined.svg'
import ClockRed from './icons/clock-red.svg'
import ChartIcon from './icons/chart.svg'
import MailOutlinedIcon from './icons/mail.svg'
import ChatIcon from './icons/chat.svg'
import BookIcon from './icons/book.svg'
import CameraIcon from './icons/camera.svg'
import ExternalLinkIcon from './icons/external-link.svg'
import SettingsIcon from './icons/settings.svg'
import TrashIcon from './icons/trash.svg'
import PencilIcon from './icons/pencil.svg'
import WoowaIcon from './icons/woowa.svg'
import TawkToIcon from './icons/tawkto.png'
import PaymentIcon from './icons/payment.svg'
import MootaIcon from './icons/moota.svg'
import ShipmentIcon from './icons/package.svg'
import SignalIcon from './icons/google-analytic.svg'
import CodeIcon from './icons/facebook-pixel.svg'
import BCA from './icons/BCA.svg'
import MANDIRI from './icons/MANDIRI.svg'
import BRI from './icons/BRI.svg'
import BNI from './icons/BNI.svg'
import PERMATA from './icons/PERMATA.svg'
import ALFAMART from './icons/ALFAMART.svg'
import INDOMARET from './icons/INDOMARET.svg'
import DANA from './icons/dana.svg'
import OVO from './icons/ovo.svg'
import LINKAJA from './icons/LINKAJA.svg'
import SupportBlue from './icons/support-blue.svg'
import SupportBlack from './icons/support-black.svg'
import Sliders from './icons/sliders.svg'
import NewCheck from './icons/new-check.svg'
import ClosePackages from './icons/close-packages.svg'
import CheckPackages from './icons/check-packages.svg'
import Whatsapp from './icons/whatsapp.svg'

const icons = {
  TawkToIcon,
  WoowaIcon,
  PaymentIcon,
  MootaIcon,
  ShipmentIcon,
  SignalIcon,
  CodeIcon,
  TrashIcon,
  PencilIcon,
  SettingsIcon,
  ExternalLinkIcon,
  CameraIcon,
  BCA,
  MailOutlinedIcon,
  ChatIcon,
  BookIcon,
  ChartIcon,
  ClockRed,
  ShppingBagOutlined,
  FloatingButtonIcon,
  ClockIcon,
  BoxIcon,
  TrendingUpIcon,
  ShoppingBagIcon,
  KeyIcon,
  LogoutIcon,
  UserIcon,
  WomanWithLaptopIcon,
  DashboardIcon,
  DashboardBlack,
  HomeIcon,
  HomeBlack,
  IntergrationIcon,
  IntergrationBlack,
  FileIcon,
  FileBlack,
  MailIcon,
  LockIcon,
  VisibilityIcon,
  AppLogo,
  AppLogoWhite,
  ChecklistCircle,
  BankTransfer,
  BcaVirtualAccount,
  DANA,
  OVO,
  GopayIcon,
  MANDIRI,
  ChecklistIcon,
  ChecklistPrimaryIcon,
  CircleGroup,
  BRI,
  BNI,
  ALFAMART,
  INDOMARET,
  PERMATA,
  LINKAJA,
  SupportBlue,
  SupportBlack,
  Sliders,
  NewCheck,
  ClosePackages,
  CheckPackages,
  Whatsapp,
}

export default icons
