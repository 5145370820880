/* eslint-disable react/prop-types */
import React from 'react'
import {
  Box,
  FormHelperText,
  InputAdornment, InputBase, makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Controller } from 'react-hook-form'
import icons from '@assets/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    height: 70,
  },
  button: {
    backgroundColor: '#F6F9FB',
    borderRadius: 5,
    padding: theme.spacing(1),
  },
  error: {
    border: 'solid 0.5px red',
  },
  errorMessage: {
    color: 'red',
  },
}))

const InputTextEmail = ({ isSubmitting, ...props }) => {
  const classes = useStyles()
  const { errors, name } = props

  return (
    <Box className={classes.root}>
      <InputBase
        {...props}
        disabled={isSubmitting}
        className={clsx(classes.button, {
          [classes.error]: !!errors[name],
        })}
        startAdornment={(
          <InputAdornment position="start">
            <img
              src={icons.MailIcon}
              alt="email"
              height={26}
              width={26}
            />
          </InputAdornment>
      )}
      />
      <FormHelperText
        className={classes.errorMessage}
      >
        { !!errors[name] && errors[name].message}
      </FormHelperText>
    </Box>
  )
}

const FieldTextEmail = ({
  // eslint-disable-next-line react/prop-types
  register, placeholder, errors, isSubmitting, inputProps, ...props
}) => (
  <Controller
    {...props}
    render={({
      onChange, onBlur,
    }) => (
      <InputTextEmail
        name={props.name}
        onBlur={onBlur}
        onChange={onChange}
        inputRef={register}
        isSubmitting={isSubmitting}
        errors={errors}
        placeholder={placeholder}
      />
    )}
  />
)

FieldTextEmail.defaultProps = {
  errors: {},
}

FieldTextEmail.propTypes = {
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
}

export default FieldTextEmail
