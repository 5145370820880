/* eslint-disable prefer-template */
/* eslint-disable react/prop-types */
import { Typography, makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import FieldText from '@components/CustomFields/FieldText'
import FieldDistributor from '@components/CustomFields/FieldDistributor'
import FieldTextPassword from '@components/CustomFields/FieldTextPassword'
import FieldAutocomplete2 from '@components/CustomFields/FieldAutocomplete2'
import FieldDomain from '@components/CustomFields/FieldDomain'
import { saasApi } from '@api/saasApi'
import useQuery from '@hooks/useQuery'

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    width: '90%',
    flexDirection: 'column',
    marginTop: 100,
    height: '100%',
  },
  txReceiver: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  passwordContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const FormRegister = ({
  register, isSubmitting, getValues, errored, ...props
}) => {
  const classes = useStyles()
  const { setValue, watch } = props
  const getDistrict = (params) => saasApi.get(
    `api/v1/districts/${params ? '?district_or_city=' + params : ''}`,
  )
  const query = useQuery()
  const packageCustomDomain = watch('package_custom_domain') || false

  useEffect(() => {
    if (query.get('ref')) {
      localStorage.setItem('ref', query.get('ref'))
      setValue('affiliate_code', query.get('ref'))
    }
  }, [query])

  return (
    <div className={classes.form}>
      <Typography variant="h6" className={classes.txReceiver}>
        Data Pengguna
      </Typography>
      <input type="text" ref={register} name="affiliate_code" hidden />
      <FieldText
        name="name"
        label="Nama Lengkap"
        register={register}
        getValues={getValues}
        disabled={isSubmitting}
        {...props}
      />
      <FieldText
        name="phone_number"
        label="Nomor Hp/Whatsapp"
        register={register}
        getValues={getValues}
        disabled={isSubmitting}
        {...props}
      />
      <FieldText
        name="email"
        label="Email"
        register={register}
        getValues={getValues}
        disabled={isSubmitting}
        {...props}
      />
      <div className={classes.passwordContainer}>
        <FieldTextPassword
          name="password"
          label="Kata Sandi"
          register={register}
          isSubmitting={isSubmitting}
          {...props}
        />
        <FieldTextPassword
          name="password_confirmation"
          label="Konfirmasi Kata Sandi"
          register={register}
          isSubmitting={isSubmitting}
          {...props}
        />
      </div>

      <Typography variant="h6" className={classes.txReceiver}>
        Data Toko
      </Typography>
      <FieldText
        name="store_name"
        label="Nama Toko"
        register={register}
        getValues={getValues}
        disabled={isSubmitting}
        {...props}
      />
      <FieldDomain
        name="domain"
        label="Domain"
        register={register}
        getvalues={getValues}
        domain="squids.id"
        disabled={isSubmitting}
        watch={watch}
        canCustomDomain={packageCustomDomain}
        {...props}
      />
      <FieldText
        name="total_reseller"
        label="Jumlah Reseller"
        register={register}
        getValues={getValues}
        disabled={isSubmitting}
        {...props}
      />
      <FieldDistributor
        name="is_distributor"
        label="Apakah Memiliki Distributor?"
        register={register}
        getValues={getValues}
        disabled={isSubmitting}
        errors={errored}
        {...props}
      />
      <FieldAutocomplete2
        name="district_id"
        label="Kota/Kecamatan"
        api={getDistrict}
        placeholder="Cari Kota..."
        register={register}
        getOptionValue={(option, value) => option.id === value.id}
        getOptionLabel={(value) => value.name}
        {...props}
      />
      <FieldText
        name="address"
        label="Alamat"
        register={register}
        getValues={getValues}
        disabled={isSubmitting}
        {...props}
      />
      <FieldText
        name="postal_code"
        label="Kode Pos"
        register={register}
        getValues={getValues}
        disabled={isSubmitting}
        {...props}
      />
    </div>
  )
}

export default FormRegister
