import React, { useEffect, useState } from 'react'
import {
  Backdrop,
  Box, Button, CircularProgress, Paper, Typography,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import NumberFormat from 'react-number-format'
import { Helmet } from 'react-helmet'
import 'dayjs/locale/id'
import icons from '@assets/icons'
import images from '@assets/images'
import { getBanks } from '@api/getBanks'
import useStyles from './styles-jss/registerPayment-jss'

const ManualPayment = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [payload] = useState([])
  const [banks, setBanks] = useState([])
  // eslint-disable-next-line no-unused-vars
  const { enqueueSnackbar } = useSnackbar()
  const [paymentDueDate] = useState('')
  const history = useHistory()

  useEffect(() => {
    const checkPayment = async () => {
      try {
        setLoading(true)
        const { data: { data, status } } = await getBanks()

        if (status === 'ok') {
          setLoading(false)
          setBanks(data)
        } else {
          enqueueSnackbar(data, {
            variant: 'error',
          })
          setTimeout(() => {
            window.location = '/'
          }, 3000)
        }
      } catch (error) {
        setLoading(false)
      }
    }

    checkPayment()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderActivePayment = () => (
    <div className={classes.wrapper}>
      <Paper className={classes.paper}>

        <img
          src={icons.AppLogo}
          height={138}
          width={110}
          alt="logo"
          className={classes.logo}
        />

        <Typography
          variant="h6"
          className={classes.title}
          gutterBottom
        >
          Terima kasih telah melakukan registrasi
        </Typography>

        <Typography
          variant="body2"
          className={classes.txDescription}
          gutterBottom
        >
          Silahkan lakukan pembayaran sebelum
        </Typography>

        <Typography
          variant="body2"
          className={classes.txDescription}
          gutterBottom
        >
          <strong> {paymentDueDate} </strong>
        </Typography>

        <Typography
          variant="body2"
          className={classes.txDescription}
          gutterBottom
        >
          Untuk menyelesaikan proses registrasi, dan transfer sejumlah:
        </Typography>

        <Typography
          variant="body2"
          className={classes.price}
          gutterBottom
        >
          <NumberFormat
            displayType="text"
            value={payload?.price}
            thousandSeparator=","
            decimalSeparator="."
            prefix="Rp. "
          />
        </Typography>

        <Paper className={classes.info}>
          <strong>Penting! </strong>
          Mohon transfer sesuai sampai dengan 3 digit terakhir
        </Paper>

        <Typography variant="caption" gutterBottom>
          Silahkan Transfer ke salah satu bank berikut:
        </Typography>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {
            banks.map((item) => (
              <Box display="flex" alignItems="flex-start" key={item.bank_name}>
                <Box margin={2} height={32} width={64}>
                  { item.icon && (
                  <img
                    src={item.icon}
                    alt={item.code}
                    height={32}
                    width={64}
                  />
                  )}
                </Box>
                <Box margin={2} display="flex" flexDirection="column" flex={1}>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: '#1C1977',
                    }}
                  >
                    {item.bank_name}
                  </Typography>
                  <Typography style={{ fontSize: 14 }}>
                    {item.name}
                  </Typography>
                  <Typography style={{ fontSize: 14 }}>
                    {item.account_number}
                  </Typography>
                </Box>
              </Box>
            ))
          }
        </Box>

        <Button
          color="primary"
          style={{ marginTop: 16 }}
          variant="contained"
          onClick={() => history.goBack()}
        >
          Kembali ke halaman awal
        </Button>
      </Paper>
    </div>
  )

  return (
    <Box className={classes.root}>
      <Helmet>
        <title>Squids Payment</title>
      </Helmet>
      <img
        src={images.BgTree}
        alt="tree"
        height="auto"
        width="100%"
        className={classes.bgImage}
      />
      <Backdrop
        open={loading}
        className={classes.backdrop}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
      {renderActivePayment()}
    </Box>
  )
}

export default ManualPayment
