import { makeStyles } from '@material-ui/core'

export const pallete = {
  primary: {
    main: '#556fb5',
  },
  secondary: {
    main: '#24E09B',
  },
  darkGreen: '#4caf50',
  lightPink: '#FFB4AC',
  darkPink: '#EF6C57',
  purple: '#9873B9',
  black: '#060A12',
  lightGreen: '#F6F9FB',
}

export const useColors = makeStyles(() => ({
  darkGreen: { backgroundColor: '#4BAEA0' },
  lightPink: { backgroundColor: '#FFB4AC' },
  darkPink: { backgroundColor: '#EF6C57' },
  purple: { backgroundColor: '#9873B9' },
  black: { backgroundColor: '#060A12' },
  lightGreen: { backgroundColor: '#F6F9FB' },
}))
