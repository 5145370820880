/* eslint-disable no-shadow */
import { useState } from 'react'

const useAxios = (axios, method, url) => {
  const api = axios

  const [isFetching, setIsFetching] = useState(false)
  const [status, setStatus] = useState(null)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  const fetch = async (params) => {
    try {
      setIsFetching(true)
      setData(null)
      const { status, data } = await api[method](url, params)
      const normalize = data.data || data

      if (status === 200) {
        setData(normalize)
        setError(null)
      } else {
        setError(normalize)
      }
      setStatus(status)
      setIsFetching(false)
    } catch (error) {
      setData(null)
      setError(error)
      setIsFetching(false)
    }
  }

  return {
    isFetching,
    fetch,
    data,
    error,
    status,
  }
}

export default useAxios
