/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import {
  Box,
  FormHelperText,
  InputAdornment,
  InputBase,
  InputLabel,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Controller } from 'react-hook-form'
import icons from '@assets/icons'
import useStyles from './styles-jss'

const InputDomain = (props) => {
  const classes = useStyles()
  const {
    errors,
    name,
    label,
    getvalue,
    domain,
    watch,
    canCustomDomain,
  } = props

  const customDomain = watch('custom_domain')

  const renderInputAdornment = () => (
    !errors[name] && getvalue !== undefined
    && (
    <img
      src={icons.ChecklistCircle}
      alt="checklist"
      height={20}
      width={20}
    />
    )
  )

  const renderErrorMessage = () => (
    !!errors[name] && errors[name].message
  )

  const renderInputLabel = () => (
    label && (
    <InputLabel className={classes.inputLabel}>
      {label}
    </InputLabel>
    )
  )
  return (
    <Box className={classes.root}>
      {renderInputLabel()}
      <Box
        display="flex"
        alignItems="center"
      >
        <InputBase
          {...props}
          className={clsx(classes.field, {
            [classes.error]: !!errors[name],
          })}
          endAdornment={((
            <InputAdornment position="end">
              {renderInputAdornment()}
            </InputAdornment>
          )
        )}
        />
        {
          !customDomain && (
          <Typography variant="body1" style={{ marginLeft: 8, width: '50%' }}>
            .{domain}
          </Typography>
          )
        }
      </Box>
      <Box display="flex" alignItems="center">
        { canCustomDomain && (
          <Box display="flex" alignItems="center">
            <input type="checkbox" name="custom_domain" ref={props.inputRef} />
            <Typography
              variant="caption"
              style={{ marginRight: 8 }}
            >
              Gunakan Custom Domain
            </Typography>
          </Box>
        )}
        <FormHelperText className={classes.errorMessage}>
          {renderErrorMessage()}
        </FormHelperText>
      </Box>
    </Box>
  )
}

InputDomain.defaultProps = {
  errors: {},
  placeholder: '',
  label: undefined,
  getvalue: undefined,
}

InputDomain.propTypes = {
  errors: PropTypes.object,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  getvalue: PropTypes.string,
  label: PropTypes.string,
  domain: PropTypes.string.isRequired,
}

const FieldDomain = ({
  placeholder,
  register,
  getvalues,
  label,
  errors,
  disabled,
  domain,
  watch,
  canCustomDomain,
  ...props
}) => (
  <Controller
    {...props}
    render={({
      onChange, onBlur,
    }) => (
      <InputDomain
        name={props.name}
        onBlur={onBlur}
        onChange={onChange}
        inputRef={register}
        disabled={disabled}
        errors={errors}
        label={label}
        placeholder={placeholder}
        domain={domain}
        getvalue={getvalues()[props.name]}
        watch={watch}
        canCustomDomain={canCustomDomain}
      />
    )}
  />
)

FieldDomain.defaultProps = {
  errors: {},
  placeholder: '',
  label: '',
}

FieldDomain.propTypes = {
  errors: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  getvalues: PropTypes.func.isRequired,
  label: PropTypes.string,
  domain: PropTypes.string.isRequired,
}

export default FieldDomain
