/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import icons from '../assets/icons'

const RenderCustomIcon = ({ icon, alt }) => (
  <img src={icon} alt={alt} height={24} width={24} />
)

const breadcrumb = {
  '/account': 'Akun Saya',
  '/change-password': 'Ubah kata sandi',
  '/integration/payment-gateway': 'Payment Gateway',
  '/integration/moota': 'Moota',
  '/integration/shipment': 'Shipment',
  '/integration/google-analytics': 'Google Analytics',
  '/integration/woowa': 'Woowa',
  '/integration/facebook-pixels': 'Facebook Pixels',
}

export const menu = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    link: '/dashboard',
    icon: <RenderCustomIcon icon={icons.DashboardBlack} alt="dashboard-black" />,
    iconSelected: <RenderCustomIcon icon={icons.DashboardIcon} alt="dashboard" />,
    breadcrumb: {
      '/dashboard': 'Dashboard',
    },
  },
  {
    id: 'store',
    title: 'Toko Saya',
    icon: <RenderCustomIcon icon={icons.HomeBlack} alt="home-black" />,
    iconSelected: <RenderCustomIcon icon={icons.HomeIcon} alt="home" />,
    child: [
      {
        id: 'list-store',
        title: 'Daftar Toko',
        link: '/list-store',
        breadcrumb: {
          '/list-store': 'Daftar Toko',
        },
      },
      {
        id: 'users',
        title: 'Atur User',
        link: '/users',
        breadcrumb: {
          '/users': 'Atur User',
        },
      },
    ],
  },
  {
    id: 'billing',
    title: 'Tagihan',
    link: '/billing',
    icon: <RenderCustomIcon icon={icons.FileBlack} alt="file-black" />,
    iconSelected: <RenderCustomIcon icon={icons.FileIcon} alt="file" />,
    breadcrumb: {
      '/billing': 'Tagihan',
    },
  },
  {
    id: 'balance',
    title: 'Riwayat Saldo',
    link: '/balance',
    icon: <RenderCustomIcon icon={icons.FileBlack} alt="file-black" />,
    iconSelected: <RenderCustomIcon icon={icons.FileIcon} alt="file" />,
    breadcrumb: {
      '/balance': 'Riwayat Saldo',
    },
  },
  {
    id: 'integration',
    title: 'Integrasi',
    link: '/integration',
    icon: <RenderCustomIcon icon={icons.IntergrationBlack} alt="integration-black" />,
    iconSelected: <RenderCustomIcon icon={icons.IntergrationIcon} alt="integration" />,
    breadcrumb: {
      '/integration': 'Integrasi',
    },
  },
  {
    id: 'support',
    title: 'Bantuan',
    link: '/support',
    icon: <RenderCustomIcon icon={icons.SupportBlack} alt="support" />,
    iconSelected: <RenderCustomIcon icon={icons.SupportBlue} alt="support" />,
    breadcrumb: {
      '/support': 'Bantuan',
    },
  },
]

export const breadcrumbs = () => {
  const newBreadcrumb = breadcrumb

  // eslint-disable-next-line array-callback-return
  menu.map((item) => {
    if (item.breadcrumb) {
      Object.assign(newBreadcrumb, item.breadcrumb)
    } else if (item.child) {
      item.child.map((item2) => Object.assign(newBreadcrumb, item2.breadcrumb))
    }
  })

  return breadcrumb
}
