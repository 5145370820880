import { yupResolver } from '@hookform/resolvers/yup'
import { rules, schema } from '@config/yup.schema'
import * as yup from 'yup'

const validateDomain = (value) => /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,})$/.test(
  value,
)

const hookFormConfig = {
  defaultValues: {
    name: '',
    email: '',
    phone_number: '',
    password: '',
    password_confirmation: '',
    address: '',
    payment_method: '',
    price: '',
    provider: 'email',
    package_name: '',
    store_name: '',
    is_distributor: null,
    total_reseller: null,
    district_id: null,
    postal_code: '',
    domain: '',
    affiliate_code: localStorage.getItem('ref') || '',
    type_package_period: 'monthly',
    bank_id: '',
    bank_name: '',
    bank_number: '',
  },
  resolver: yupResolver(
    schema({
      name: rules.stringRequired,
      email: rules.email,
      phone_number: rules.phoneNumber,
      password: rules.password,
      password_confirmation: rules.password,
      address: rules.stringRequired,
      payment_method: rules.paymentMethod2,
      package_name: rules.stringRequired,
      district_id: rules.mixed,
      postal_code: rules.number,
      store_name: rules.stringRequired,
      type_package_period: rules.stringRequired,
      domain: yup
        .string()
        .required('tidak boleh kosong')
        .when('custom_domain', {
          is: (value) => value,
          then: yup
            .string()
            .test('domain', 'domain tidak valid', validateDomain),
        }),
      total_reseller: rules.number,
      is_distributor: yup
        .mixed()
        .test('require', 'tidak boleh kosong', (value) => value !== null),
      bank_id: rules.mixed,
      bank_name: rules.mixed,
      bank_number: rules.mixed,
    }),
  ),
}

export default hookFormConfig
