import { makeStyles } from '@material-ui/core'
import { drawerWidth } from '@themes/constant'

const mobileMenuUserHeight = 150

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      zIndex: theme.zIndex.appBar + 1,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    borderRadius: 0,
    overflowY: 'hidden',
    overflowX: 'hidden',
    '&:hover': {
      overflowY: 'auto',
    },
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      backgroundColor: 'RGBA(85,111,181,0.6)',
    },
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
    },
  },
  drawerHeader: {
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    height: 140,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  drawerButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  drawerButtonIcon: {
    color: 'white',
    fontSize: 28,
  },
  drawerContent: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: mobileMenuUserHeight,
    },
  },
  list: {
    width: '100%',
    paddingTop: 0,
  },
  listItem: {
    color: '#1A253B',
    '&.Mui-selected': {
      backgroundColor: '#E5E5E5',
      color: '#556FB5',
    },
    height: '60px',
    paddingLeft: theme.spacing(5),
  },
  nested: {
    color: '#1A253B',
    '&.Mui-selected': {
      backgroundColor: '#B6C8E3',
    },
  },
  mobileMenuUser: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    backgroundColor: '#F5F5F5',
    position: 'fixed',
    width: drawerWidth,
    bottom: 0,
    height: mobileMenuUserHeight,
    zIndex: theme.zIndex.drawer + 1,
  },
  userMenuDivider: {
    width: drawerWidth,
  },
}))

export default useStyles
