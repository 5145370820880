import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#556FB5',
    },
    secondary: {
      main: '#24E09B',
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 5,
        minWidth: 100,
      },
      label: {
        fontWeight: 'bold',
        textTransform: 'capitalize',
      },
    },
    MuiPaper: {
      root: {
        borderRadius: 5,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 5,
      },
    },
  },
})

export default theme
