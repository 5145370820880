import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  dividerText: {
    marginTop: 26,
    height: 1.5,
  },
  txTitle: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '27px',
    color: '#3E3E3E',
    marginTop: 8,
  },
  card: {
    backgroundColor: theme.palette.primary.main,
    padding: '8px 18px 8px 18px',
    color: 'white',
    display: 'flex',
    height: 100,
    borderRadius: 5,
    alignItems: 'center',
  },
  txPackageName: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '34px',
    color: '#FFFFFF',
  },
  txPackagePrice: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '26px',
    lineHeight: '34px',
    color: '#FFFFFF',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  txDesc: {
    fontSize: 14,
    marginTop: 24,
  },
  btnChangePackage: {
    alignSelf: 'flex-start',
    margin: '8px 0px 8px 0px',
    fontSize: 14,
  },
  txDetails: {
    fontSize: 14,
  },
  txNotes: {
    fontSize: 14,
    color: 'grey',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  txTotalPrice: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'black',
    margin: '16px 0px 16px 0px',
  },
  txPrice: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#0AE013',
    alignSelf: 'center',
  },
  txPriceDefault: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'gray',
    alignSelf: 'center',
    textDecorationLine: 'line-through',
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  dialogContent: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  closeIcon: {
    height: 13,
    width: 13,
    color: 'red',
    marginRight: '-3px',
  },
  btnPeriodActive: {
    background: '#FEB546',
    borderRadius: '6px',
    width: '131px',
    height: '40px',
    color: '#fff',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background: '#FEB546',
    },
  },
  btnPeriod: {
    background: 'unset',
    border: 'unset',
    borderRadius: '6px',
    width: '131px',
    height: '40px',
    color: '#556FB5',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background: 'unset',
    },
  },
  tabsPackage: {
    background: 'unset',
    boxShadow: 'unset',
    border: 'unset',
    marginTop: 24,
    width: '100%',
  },
  tabStyle: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
    minWidth: '0px',
  },
  btnWa: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    background: '#24E09B',
    borderRadius: '6px',
    '&:hover': {
      background: '#24E09B',
    },
  },
  textWa: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '19px',
    color: '#FFFFFF',
    marginLeft: '18px',
  },
}))

export default useStyles
