import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles, Paper } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import images from '@assets/images'
import icons from '@assets/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  bgImage: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
  },
  paper: {
    display: 'flex',
    width: 670,
    padding: '60px 0px 60px 0px',
    zIndex: 1,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  logo: {
    marginBottom: 30,
  },
}))

const AuthManagement = ({ children, pageTitle }) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <img
        src={images.BgTree}
        alt="tree"
        height="auto"
        width="100%"
        className={classes.bgImage}
      />
      <Paper className={classes.paper}>
        <img
          src={icons.AppLogo}
          height={138}
          width={110}
          alt="logo"
          className={classes.logo}
        />
        {children}
      </Paper>
    </Box>
  )
}

AuthManagement.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
}

export default AuthManagement
