/* eslint-disable import/prefer-default-export */

export const setBearerToken = (request) => {
  request.headers.common.Accept = 'application/json'
  request.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`

  return request
}

export const handleUnatuhorized = async (error) => {
  const { response } = error
  if (response?.status === 401) {
    await localStorage.clear()
    window.location = '/'
  }

  return Promise.reject(error)
}
