import { Button, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import useQuery from '@hooks/useQuery'
import AuthManagement from '@layout/AuthManagement'
import useStyles from './forgot-jss'

const ForgotResult = () => {
  const classes = useStyles()
  const query = useQuery()
  const success = query.get('success') === 'true'
  const history = useHistory()

  useEffect(() => {
    if (query.get('success') === null) history.push('/')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return (
    <AuthManagement pageTitle="Squids | Forgot Password Success">
      <Typography variant="h6" className={classes.txForgotPassword}>
        { success ? 'Email Berhasil Dikirim!' : 'Ooops Maaf, Email Tidak Terdaftar!'}
      </Typography>
      <Typography variant="body2" className={clsx(classes.txDescription)} style={{ marginBottom: 8 }}>
        { success
          ? 'Kami telah mengirimkan tautan untuk mengatur ulang kata sandi.'
          : 'Maaf kami tidak dapat menemukan email yang anda masukan atau belum terdaftar.'}
      </Typography>
      <Typography variant="body2" className={classes.txDescription}>

        { success
          ? 'Mohon periksa email Anda.'
          : 'Pastikan email anda terdaftar atau valid.'}
      </Typography>

      <Button
        color="primary"
        component={Link}
        to="/"
      >
        Kembali Ke Halaman Utama
      </Button>
    </AuthManagement>
  )
}

export default ForgotResult
