import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button, CircularProgress, Typography,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { saasApi } from '@api/saasApi'
import FieldTextEmail from '@components/CustomFields/FieldTextEmail'
import { rules, schema } from '@config/yup.schema'
import AuthManagement from '@layout/AuthManagement'
import useStyles from './forgot-jss'

const ForgotPassword = () => {
  const classes = useStyles()
  const {
    handleSubmit, control, errors, register,
  } = useForm({
    resolver: yupResolver(schema({
      email: rules.email,
    })),
  })
  const history = useHistory()
  const [isSubmitting, setSubmitting] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = async (params) => {
    try {
      setSubmitting(true)
      // eslint-disable-next-line no-shadow
      const { data: { success } } = await saasApi.post('/api/v1/saas_users/password', params)

      if (success) {
        setSubmitting(false)
        history.push(`/forgot-password/result?success=${success}`)
      } else {
        setSubmitting(false)
        history.push(`/forgot-password/result?success=${success}`)
      }
    } catch (error) {
      setSubmitting(false)
      enqueueSnackbar('Maaf, terjadi kesalahan pada sistem',
        { variant: 'error' })
    }
    //
  }

  return (
    <AuthManagement pageTitle="Squids - Forgot Password">
      <Typography variant="h6" className={classes.txForgotPassword}>
        Lupa Kata Sandi?
      </Typography>
      <Typography variant="body2" className={classes.txDescription}>
        Untuk mengatur ulang kata sandi, silahkan masukkan alamat email Anda.
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <FieldTextEmail
          errors={errors}
          control={control}
          defaultValue=""
          name="email"
          placeholder="Email"
          register={register}
          isSubmitting={isSubmitting}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.btnSubmit}
          disabled={isSubmitting}
          type="submit"
        >
          { isSubmitting ? <CircularProgress size={24} color="secondary" /> : 'Kirim'}
        </Button>
      </form>
    </AuthManagement>
  )
}

export default ForgotPassword
